/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layer } from "../../../../actions/Layers/constants";
import { getNotifications } from "../../../../actions/Mailbox/actions";
import { format } from "../../../../utils";
import AttachmentLink from "../../../Helper/AttachmentLink";
import { MetaRow } from "./LayerMeta";

export default function LayerReportsView({ layer }: { layer: Layer | undefined }) {
    const notifications = useSelector((state: any) => state.mailbox.items).results;
    const notifsWithReport = notifications?.filter((notification: any) => notification.attributes.report);
    const dispatch = useDispatch();

    useEffect(() => {
        if (layer?.id) {
            dispatch(getNotifications({ limit: 25, offset: 0, layer_id: layer.id }));
        }
    }, [layer?.id]);

    return notifsWithReport && notifsWithReport.length > 0 ? <div className="pt-4">
        <p>The reports mailed to supplier</p>
        {
            notifsWithReport.map((notif: any, index: number) => {
                const attachment_url = notif.attachment_urls.find((url) => url.includes(notif.attributes.report.blob_name));
                if (!attachment_url) {
                    console.warn("Attachment not found for notification", notif);
                    return null;
                }
                const date = format(notif.created);
                return <MetaRow key={index} label={notif.attributes.report.type} value={<AttachmentLink url={attachment_url} label={date} />} />;
            })
        }
    </div> : null;
}
