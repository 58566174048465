import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button
} from "reactstrap";
import useConfig from "../../../actions/Tenants/config/configHook";
import { listWaste } from "../../../actions/Waste/actions";
import DatePicker from "../../Forms/DatePicker";
import MetaForm from "../../Forms/MetaForm";
import AvosModal, { modalResult } from "../RipeningTE/Modal";
import AddWasteForm from "./AddWasteForm";

export default function WasteFilter() {
    const config = useConfig();
    const [modalOpen, setModal] = useState(false);
    const filter = useSelector((state) => state.waste.filter);
    const timer = useRef();

    const dispatch = useDispatch();

    const [query, setQuery] = useState(filter);
    useEffect(() => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            dispatch(listWaste({ ...filter, ...query, offset: 0 }));
        }, 300);
        return () => clearTimeout(timer.current);
    }, [query]);

    // const _onChange = (option, field) => {
    //     const value = option ? option.value : "";
    //     setQuery((query) => ({ ...query, [field]: value }));
    // };

    const onClose = (submit) => {
        if (submit !== modalResult.save) {
            setModal(false);
            return;
        }
        dispatch(listWaste({ ...query, offset: 0 }));
        setModal(false);
    };

    return (
        <div className="d-md-flex pt-4">
            {/* <div className="pb-2 pb-md-0 me-2 d-inline-flex d-md-block d-none">
                <Input type="text" placeholder="Search..." className="form-control" name="search" value={query.search} id="search" onChange={(e) => setQuery((query) => ({ ...query, search: e.target.value }))} />
            </div> */}
            <div className="pb-2 pb-md-0 me-2 d-inline-flex d-md-block">
                <div className="d-flex align-items-center">
                    <DatePicker placeholder="From" id="dateFrom" value={query.dateFrom} onChange={(value) => setQuery((query) => ({ ...query, dateFrom: value }))} />
                    <div className="px-2">to</div>
                    <DatePicker placeholder="To" id="dateTo" value={query.dateTo} onChange={(value) => setQuery((query) => ({ ...query, dateTo: value }))} />
                </div>
            </div>
            <div className="ms-auto">
            </div>
            {config.waste_filter_form
                && <div className="pb-2 pb-md-0 pe-2">
                    <Button color={config.waste_filter_form.filter((i) => query[i.name]).length > 0 ? "primary" : "light"} onClick={() => setModal(true)}>
                        <FontAwesomeIcon icon={["far", "filter"]} />
                    </Button>
                </div>}
            {config.waste_filter_form && <AvosModal
                header="Filter"
                showSaveButton={false}
                cancelButtonText="Close"
                onClose={onClose}
                isOpen={modalOpen}
                size="md"
            >
                <MetaForm meta={config.waste_filter_form} setValue={(field, value) => setQuery((query) => ({ ...query, [field]: value }))} object={query} config={config} />
            </AvosModal>}
            <div className="pb-2 pb-md-0 me-2 d-inline-flex d-md-block">
                <AddWasteForm/>
            </div>
        </div>
    );
}
