/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { PDFViewer, StyleSheet, View } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    Button, Container
} from "reactstrap";
import { AnyAction } from "redux";
import { formatCheck2Frontend } from "../../../../../actions/Checks/util";
import {
    getLayer,
    resetLayerState
} from "../../../../../actions/Layers/actions";
import { LAYER_STAGE } from "../../../../../actions/Layers/constants";
import { format2Frontend } from "../../../../../actions/Layers/util";
import useConfig, { useTenantFormOptions } from "../../../../../actions/Tenants/config/configHook";
import { getFilledArrayOrDefault } from "../../../../../utils";
import useAvosApi, { getAvosApiClient } from "../../../../../utils/useAvosApiHook";
import { TenantDefect, useDefectsHook } from "../../../../Forms/useManagedOptionsHook";
import LoadingProgressBar from "../../../../Helper/LoadingProgressBar";
import { device_image_types } from "../../../AddItem/components/Images";
import { getFruitTableData } from "../../../AddItem/components/Summary/FruitTable";
import webpToJpgBase64 from "../../../image-utils";
import { SendReportButton } from "../SendReportButton";
import PDFArrivalCheck from "./PDFArrivalCheck";
import { PDFCheckFruitTables } from "./PDFCheckFruitTables";
import PDFContainerDefects from "./PDFContainerDefects";
import { PDFImages } from "./PDFImages";
import PDFLayerMeta from "./PDFLayerMeta";
import { PDFDocument, PDFPage } from "./PDFLayout";
import PDFPalletScoringOverview from "./PDFPalletScoringOverview";
import { PDFPallets } from "./PDFPallets";

export const PDFCountDownText = [
    {
        text: "Loading data...",
        seconds: 3
    },
    {
        text: "Fetching checks...",
        seconds: 3
    },
    {
        text: "List all the fruit...",
        seconds: 3
    },
    {
        text: "Fetching images...",
        seconds: 3
    },
    {
        text: "Rendering PDF...",
        seconds: 4
    },
    {
        text: "Fit images on page...",
        seconds: 4
    },
    {
        text: "Almost there...",
        seconds: 5
    },
    {
        text: "Finishing up...",
        seconds: 3
    }
];


export default function PDFIntakeReport() {
    const config = useConfig();
    const dispatch = useDispatch();
    const user = useSelector<any>((state) => state.auth.user);
    const layer = useSelector<any, any>((state) => state.layers.current);
    const tenantsIsLoading = useSelector<any>((state) => state.tenants.isLoading);
    const form_options = useTenantFormOptions();
    const [printChildren, setChildren] = useState<any>();
    const [printChecks, setPrintChecks] = useState<any>();
    const params = useParams();
    const layer_config = config.get_layer_config(layer);
    const location = config.get_location(layer, { location: "intake" });
    // const [defectChartImageInternal, setDefectChartImageInternal] = useState<string>();
    // const [defectChartImageExternal, setDefectChartImageExternal] = useState<string>();
    const all_defects = useDefectsHook(layer.fruit_type) as TenantDefect[];

    const navigate = useNavigate();
    useEffect(() => {
        dispatch(resetLayerState());
        dispatch(getLayer(params.layer_id!) as unknown as AnyAction);
    }, [params.layer_id]);

    // We need a local state here becuase the pdf rendered cannot reach the react store
    useEffect(() => {
        if (!layer.id || (all_defects?.length || 0) === 0 || layer?.id !== params.layer_id) {
            return;
        }
        const fetchData = async () => {
            // fetching children
            try {
                const response = await getAvosApiClient(`/layers/${layer.id}/children`, { params: { latest_check_location: LAYER_STAGE.INTAKE } });
                const children = response.data;

                const children_with_intake = children.results.filter((i) => i.latest_check?.test_id).sort((a, b) => a.latest_check.test_id - b.latest_check.test_id);
                const intake_check_ids = children_with_intake.map((i) => i.latest_check?.test_id);
                const fetch_intake_checks = intake_check_ids.map((test_id) => getAvosApiClient(`/tests/${test_id}/`));
                const checks = await Promise.all(fetch_intake_checks);

                // * get formatted intake checks, preload images and replace URLs with Blob URLs
                const formatted_checks = await Promise.all(checks.map(async (i) => {
                    const data = formatCheck2Frontend(i.data);
                    const all_images = [
                        ...(data.images ? data.images.map((x) => ({ ...x, label: data.label })) : []),
                        ...(data.avocados ? data.avocados.flatMap((a) => a.images
                            .filter((x) => !device_image_types.includes(x.type)))
                            .filter((x) => x.url)
                            .map((x) => ({ ...x, label: data.label })) : [])
                    ];

                    // Preload all images and replace URLs with Blob URLs
                    const preloadedImages = await Promise.all(all_images.map(async (image) => ({
                        ...image,
                        url: await webpToJpgBase64(image.url)
                    })));

                    return { ...data, all_images: preloadedImages };
                }));
                // * populate the layer children (pallets) with the formatted intake checks
                const updatedChildren = children_with_intake
                    .map(format2Frontend)
                    .map((child, index) => (
                        // we overwrite the latest check here because the `/tests/{test_id}` endpoint will also return all images and underlying avocados while the layer endpoint does not
                        {
                            ...child,
                            latest_check: {
                                ...child.latest_check, // this object contains aggregrated fruit
                                ...formatted_checks[index], // this object also contains images and underlying avocados
                                label: `${child.label}`, // label for the images
                                table_data: getFruitTableData({ // this function generates the data for indivual fruit TODO: refactor to fields api
                                    layer: child,
                                    check: formatted_checks[index],
                                    fruits: formatted_checks[index].avocados,
                                    config,
                                    user,
                                    all_defects
                                }),
                                ...child.meta
                            }
                        }));

                setChildren(updatedChildren);
                setPrintChecks(updatedChildren.map((i) => i.latest_check));


            } catch (error) {
                console.error("Error fetching data:", error); // eslint-disable-line no-console
            }
        };

        fetchData();


    }, [layer.id, all_defects?.length]);

    const pdf_config = config.get_pdf_report(layer, "intake");

    const isLoading: boolean = (!layer_config || !printChecks || !printChildren || !pdf_config || tenantsIsLoading || all_defects.length === 0) as boolean;

    const default_options = {
        children_title: "Pallets",
        children_table_fields: [],
        children_check_summary_meta_fields: [],
        show_graphs: true, // by default is true for all tenants
        check_meta_fields: [],

    };


    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            justifyContent: "space-between",
            height: "70vh",
            marginTop: "20px"
        },
        cell1: {
            width: "35%", // Adjust based on your layout
        },
        cell2: {
            width: "63%", // Adjust based on your layout
        },
        row2: {
            flexDirection: "row",
            justifyContent: "space-between",
            height: "50vh",
        },
    });

    const PrintReport = ({ onRender }) => {
        const { children_table_fields, children_title, children_note, logo_name, children_check_summary_meta_fields, check_meta_fields } = { ...default_options, ...pdf_config } as any;
        const defect_groups = location.defect_groups || [];
        const layer_fields = layer_config.meta_display.map((i) => i.set_value({
            config,
            layer,
            check: layer.latest_check, // * To be used in check tables and layer overview
            fruit: null, // * Fruit list is not available on overview screen
            children: { count: printChildren?.length || 0, results: getFilledArrayOrDefault(printChildren, []) }, // * children is only available for layer index your are viewing
            form_options // * used to translate form values to labels
        }));

        const children_layer_fields = printChildren.map((child) => {
            return children_check_summary_meta_fields.map((i) => i.clone().set_value({
                config,
                layer: child,
                check: child.latest_check, // * To be used in check tables and layer overview
                fruit: null,
                children: null,
                form_options // * used to translate form values to labels
            }));
        });

        const arrival_check_fileds = layer_config.show_arrival_check?.fields.map((i) => i.clone().set_value({
            config,
            layer,
            check: layer.latest_check, // * To be used in check tables and layer overview
            fruit: null,
            children: null,
            form_options // * used to translate form values to labels
        }));

        return (
            <>
                <PDFDocument onRender={onRender} title={`Intake report ${layer?.label}`} >
                    <PDFPage logo={logo_name} header_title="Intake check report">
                        <View style={styles.row} wrap={false}>
                            <View style={styles.cell1}>
                                <PDFLayerMeta layer={layer} fields={layer_fields} status={layer.intake_manual_flag ? layer.intake_manual_flag : layer.intake_status} />;
                            </View>
                            <View style={styles.cell2}>
                                {defect_groups.find((item) => item.total_defects_title) && <View style={styles.row2} wrap={false}>
                                    {defect_groups.filter((item) => item.total_defects_title).map((group, index) => <PDFContainerDefects key={index} all_defects={all_defects} defect_group={group} printChecks={printChecks} />)}
                                </View>}
                                <PDFPalletScoringOverview printChildren={printChildren} status={layer.intake_status} />
                            </View>
                            {/* {show_graphs && <View style={styles.cell2}>
                                <View>
                                    {defectChartImageInternal && <PDFChart chart={defectChartImageInternal} title={internal_defects_title} />}
                                </View>
                                <View>
                                    {defectChartImageExternal && <PDFChart chart={defectChartImageExternal} title={external_defects_title} />}
                                </View>
                            </View>} */}
                        </View>
                    </PDFPage>
                    {
                        arrival_check_fileds && <PDFPage>
                            <PDFArrivalCheck fields={arrival_check_fileds} />;
                        </PDFPage>
                    }
                    {children_table_fields.length > 0 && <PDFPallets
                        fields={children_table_fields}
                        printChildren={printChildren}
                        config={config}
                        children_title={children_title}
                        form_options={form_options}
                    />}
                    {printChecks.map((i, index) => [<PDFCheckFruitTables
                        key={index + 0.1}
                        children_layer_fields={children_layer_fields}
                        header={i.table_data[0]}
                        children_note={i[children_note?.fieldname_getter]}
                        rows={i.table_data[1]}
                        footer={i.table_data[2]}
                        checkIndex={index}
                        all_defects={all_defects}
                        defect_groups={defect_groups}
                        check={i}
                        check_meta_fields={check_meta_fields}
                    />,
                    <PDFImages key={index + 0.2} images={i.all_images} />
                    ])}
                </PDFDocument>
            </>
        );
    };

    const [onSendReport] = useAvosApi("/notification/report-to-supplier", "POST", {}, {
        onSuccess: {
            message: "Report sent successfully",
            showMessage: true
        }
    });
    const reportType = "intake";
    const [pdfBlob, setPdfBlob] = useState<Blob>();
    const onRender = async ({ blob }) => {
        if (!pdfBlob || pdfBlob.size !== blob.size) {
            setPdfBlob(blob);
        }
    };

    return <div className="px-3 py-4 bg-gray">
        <Container>
            <div className="d-flex justify-content-end align-items-center">
                <div className="pb-2 pb-sm-0 me-2 d-inline-flex d-md-block">
                    <h3 className="mb-0 ">{layer_config?.text} {layer.label} </h3>
                </div>
                <div className="pb-2 pb-sm-0 d-flex align-items-center ms-auto">
                    {pdf_config?.show_send_email_button && onSendReport && <SendReportButton layerId={layer.id} blob={pdfBlob} reportType={reportType} onSendReport={onSendReport as any} />}
                    <Button className="btn-close my-1" size="lg" onClick={() => navigate(`/layer/${layer.id}`)} ></Button>
                </div>
            </div>
        </Container>
        <Container className="py-5">
            <div css={css`margin:auto;width: 420mm;`} >
                {isLoading ? (
                    <LoadingProgressBar text={PDFCountDownText.slice(0, 4)} />
                ) : (
                    <div>
                        {!pdfBlob && <LoadingProgressBar text={PDFCountDownText} startIndex={4} />}
                        <PDFViewer style={{ width: "100%", height: "1200px", opacity: pdfBlob ? 1 : 0 }}>
                            <PrintReport onRender={onRender} />
                        </PDFViewer>
                    </div>
                )}
                {/* <div className="d-none">
                    <PlotlyBarchartHorizontal data={internalData} saveImageData={setDefectChartImageInternal} />
                    <PlotlyBarchartHorizontal data={externalData} saveImageData={setDefectChartImageExternal} />
                </div> */}
            </div>
        </Container>
    </div>;
}

