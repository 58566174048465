import { useState } from "react";
import { useSelector } from "react-redux";
import { UserPreferences } from "../actions/Users/actions";
import useConfig from "../actions/Tenants/config/configHook";

export const useUserPreferences = () => {
    const userPreferences = useSelector<any, UserPreferences>((state) => state.auth.user.preferences);
    return userPreferences;
};

export const useFruitTypes = () => {
    const [fruitTypes, setFruitTypes] = useState<string[]>([]);
    const config = useConfig();
    const userPreferences = useUserPreferences();
    const allOptions = useSelector((state: any) => state?.tenants?.options_by_field);
    const fruitTypeOptions = allOptions?.[config.tenant_id]?.fruit_type || [];
    const preferredFruitTypes = userPreferences?.fruit_types.filter((fruitType) => fruitTypeOptions.find((option) => option.value === fruitType)) || [];
    // check shallow equality of arrays and update state if needed
    if (preferredFruitTypes.length !== fruitTypes.length || preferredFruitTypes.some((fruitType, index) => fruitType !== fruitTypes[index])) {
        setFruitTypes(preferredFruitTypes);
    }
    return fruitTypes;
};
