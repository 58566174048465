import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";

import useConfig from "../../../actions/Tenants/config/configHook";
import { useFruitTypes } from "../../../utils/useUserPreferences";
import MetaForm from "../../Forms/MetaForm";
import { useListLayersDebounced } from "./utils";


export default function GeneralLayerFilter() {
    const config = useConfig();
    const [isOpen, setModal] = useState(false);
    const filter = useSelector<any, any>((state) => state.layers.filter);
    const selectedFruitTypes = useFruitTypes();
    const fields = config.root_config?.overview_general_filter_form?.map((i) => i.field) || [];
    const fields_not_fruit_type = fields.filter((i) => i.name !== "fruit_type");
    const [listLayersDebounced] = useListLayersDebounced();

    const onReset = () => {
        const filterEraser = fields_not_fruit_type.reduce((acc, i) => ({ ...acc, [i.name]: undefined, }), {});
        listLayersDebounced({ offset: 0, ...filter, ...filterEraser, fruit_type: selectedFruitTypes });
    };

    const filter_in_use = fields_not_fruit_type.some((field) => filter[field.name] !== undefined);
    return <div>
        {fields_not_fruit_type && <>
            <Button color={filter_in_use ? "primary" : "light"} onClick={() => setModal(true)}>
                <FontAwesomeIcon icon={faFilter} />
            </Button>
        </>}
        <Modal size="md" isOpen={isOpen} toggle={() => setModal(false)} >
            <ModalHeader toggle={() => setModal(false)} >Filter</ModalHeader>
            <ModalBody>
                <MetaForm
                    meta={fields}
                    setValue={(field, value) => {
                        listLayersDebounced({ offset: 0, ...filter, [field]: value, fruit_type: selectedFruitTypes });
                    }}
                    object={filter}
                    config={config}
                />
            </ModalBody>
            <ModalFooter>
                <Button color="light" onClick={() => setModal(false)}>Close</Button>
                <Button color="warning" outline={!filter_in_use} disabled={!filter_in_use} onClick={() => onReset()}>Reset</Button>
            </ModalFooter>
        </Modal >
    </div>;
}
