/** @jsxImportSource @emotion/react */
import { faFlagSwallowtail } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CheckField from "./CheckField";

class CheckFlagField extends CheckField {
    field_type = "check_flag_field";

    backend_table = "check";

    convert_flag_state_color(state: string | null | undefined) {
        if (state === "-") {
            return "light";
        }
        if (state) {
            const output = state.split("flag-").pop();
            return output;
        }
        return "light";
    }

    display_component(): JSX.Element {
        return <span className={`text-${this.convert_flag_state_color(this.value)}`}>
            <FontAwesomeIcon icon={faFlagSwallowtail} />
        </span>;
    }

}

export default CheckFlagField;
