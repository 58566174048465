
export const loadImage = async (logo_name) => {
    try {
        const logo_image = await import(`../../../../../img/${logo_name}`);
        return logo_image.default;
    } catch (error) {
        return null;
    }
};

export const getFlagColor = (flag, default_color = "#4f4f4f") => {
    if (flag === "success" || flag === "flag-success") {
        return "#198754";
    } if (flag === "warning" || flag === "flag-warning") {
        return "#ffc107";
    } if (flag === "danger" || flag === "flag-danger") {
        return "#dc3545";
    }
    // Default color if the flag doesn't match any of the above
    return default_color;
};


export const globalPacificDefects = [
    {
        category: "unacceptable",
        label: "Waste, Rots & Mould, Decay",
        value: "waste,-rots-&-mould,-decay",
        business_rules: {
            danger: { threshold: 1.41 },
            warning: { min: 1.31, max: 1.40 },
            success: { threshold: 1.30 }
        }
    },
    {
        category: "unacceptable",
        label: "Foreign Bodies / Dead, Live Pests",
        value: "foreign-bodies-/-dead,-live-pests",
        business_rules: {
            danger: { threshold: 1.41 },
            warning: { min: 1.31, max: 1.40 },
            success: { threshold: 1.30 }
        }
    },
    {
        category: "unacceptable",
        label: "Residue / Spray Deposits",
        value: "residue-/-spray-deposits",
        business_rules: {
            danger: { threshold: 1.41 },
            warning: { min: 1.31, max: 1.40 },
            success: { threshold: 1.30 }
        }
    },
    {
        category: "major",
        label: "Isolated Waste, Rots",
        value: "isolated-waste,-rots",
        business_rules: {
            danger: { threshold: 5.01 },
            warning: { min: 4.01, max: 5 },
            success: { threshold: 4 }
        }
    },
    {
        category: "major",
        label: "Major Shrivel / Dehydration",
        value: "major-shrivel-/-dehydration",
        business_rules: {
            danger: { threshold: 5.01 },
            warning: { min: 4.01, max: 5 },
            success: { threshold: 4 }
        }
    },
    {
        category: "major",
        label: "Heavy Bruising / Mottling",
        value: "heavy-bruising-/-mottling",
        business_rules: {
            danger: { threshold: 5.01 },
            warning: { min: 4.01, max: 5 },
            success: { threshold: 4 }
        }
    },
    {
        category: "major",
        label: "Deep Unhealed Wounds",
        value: "deep-unhealed-wounds-(skin-punctures-/-cuts-/-mechanical-damage)",
        business_rules: {
            danger: { threshold: 5.01 },
            warning: { min: 4.01, max: 5 },
            success: { threshold: 4 }
        }
    },
    {
        category: "major",
        label: "Major Scarring",
        value: "major-scarring",
        business_rules: {
            danger: { threshold: 5.01 },
            warning: { min: 4.01, max: 5 },
            success: { threshold: 4 }
        }
    },
    {
        category: "major",
        label: "Open Split Stone",
        value: "open-split-stone",
        business_rules: {
            danger: { threshold: 5.01 },
            warning: { min: 4.01, max: 5 },
            success: { threshold: 4 }
        }
    },
    {
        category: "major",
        label: "Soiling",
        value: "soiling",
        business_rules: {
            danger: { threshold: 5.01 },
            warning: { min: 4.01, max: 5 },
            success: { threshold: 4 }
        }
    },
    {
        category: "major",
        label: "Skin Inking (black spots)",
        value: "skin-inking-(black-spots)",
        business_rules: {
            danger: { threshold: 5.01 },
            warning: { min: 4.01, max: 5 },
            success: { threshold: 4 }
        }
    },
    {
        category: "major",
        label: "Hail damage",
        value: "hail-damage",
        business_rules: {
            danger: { threshold: 5.01 },
            warning: { min: 4.01, max: 5 },
            success: { threshold: 4 }
        }
    },
    {
        category: "minor",
        label: "Green Colour",
        value: "green-colour",
        business_rules: {
            danger: { threshold: 10.01 },
            warning: { min: 5.01, max: 10 },
            success: { threshold: 5 }
        }
    },
    {
        category: "minor",
        label: "Light Bruising",
        value: "light-bruising",
        business_rules: {
            danger: { threshold: 10.01 },
            warning: { min: 5.01, max: 10 },
            success: { threshold: 5 }
        }
    },
    {
        category: "minor",
        label: "Mechanical Damage",
        value: "mechanical-damage-(lines-of-cuts-&-ambrasions-to-the-skin)",
        business_rules: {
            danger: { threshold: 10.01 },
            warning: { min: 5.01, max: 10 },
            success: { threshold: 5 }
        }
    },
    {
        category: "minor",
        label: "White Discolouration",
        value: "white-discolouration",
        business_rules: {
            danger: { threshold: 10.01 },
            warning: { min: 5.01, max: 10 },
            success: { threshold: 5 }
        }
    },
    {
        category: "minor",
        label: "Single Wound",
        value: "single-wound-(skin-puncture-/-cut)",
        business_rules: {
            danger: { threshold: 10.01 },
            warning: { min: 5.01, max: 10 },
            success: { threshold: 5 }
        }
    },
    {
        category: "minor",
        label: "Light Shrivel",
        value: "light-shrivel-(light-wrinkling-around-stem)",
        business_rules: {
            danger: { threshold: 10.01 },
            warning: { min: 5.01, max: 10 },
            success: { threshold: 5 }
        }
    },
    {
        category: "minor",
        label: "Minor Scarring",
        value: "minor-scarring-(dry,-brown-to-grey-irregular-patches-on-skin-<-20mm)",
        business_rules: {
            danger: { threshold: 10.01 },
            warning: { min: 5.01, max: 10 },
            success: { threshold: 5 }
        }
    },
    {
        category: "minor",
        label: "Colour Blush",
        value: "colour-blush",
        business_rules: {
            danger: { threshold: 10.01 },
            warning: { min: 5.01, max: 10 },
            success: { threshold: 5 }
        }
    },
    {
        category: "minor",
        label: "Thrip Damage",
        value: "thrip-damage",
        business_rules: {
            danger: { threshold: 10.01 },
            warning: { min: 5.01, max: 10 },
            success: { threshold: 5 }
        }
    },
    {
        category: "minor",
        label: "Dry, Healed Stalk Tear",
        value: "dry,-healed-stalk-tear",
        business_rules: {
            danger: { threshold: 10.01 },
            warning: { min: 5.01, max: 10 },
            success: { threshold: 5 }
        }
    },
    {
        category: "minor",
        label: "Sunken Tips/Soft Tips",
        value: "sunken-tips/soft-tips",
        business_rules: {
            danger: { threshold: 10.01 },
            warning: { min: 5.01, max: 10 },
            success: { threshold: 5 }
        }
    },
    {
        category: "minor",
        label: "Skin Cracking",
        value: "skin-cracking",
        business_rules: {
            danger: { threshold: 10.01 },
            warning: { min: 5.01, max: 10 },
            success: { threshold: 5 }
        }
    },
    {
        category: "minor",
        label: "Soft",
        value: "soft",
        business_rules: {
            danger: { threshold: 10.01 },
            warning: { min: 5.01, max: 10 },
            success: { threshold: 5 }
        }
    },
    {
        category: "minor",
        label: "Misshapen",
        value: "misshapen",
        business_rules: {
            danger: { threshold: 10.01 },
            warning: { min: 5.01, max: 10 },
            success: { threshold: 5 }
        }
    },
    {
        category: "minor",
        label: "Undersized Fruits",
        value: "undersized-fruits",
        business_rules: {
            danger: { threshold: 10.01 },
            warning: { min: 5.01, max: 10 },
            success: { threshold: 5 }
        }
    },
    {
        category: "minor",
        label: "Excessive Nap",
        value: "excessive-nap",
        business_rules: {
            danger: { threshold: 10.01 },
            warning: { min: 5.01, max: 10 },
            success: { threshold: 5 }
        }
    },
    {
        category: "minor",
        label: "Pest Damage",
        value: "pest-damage",
        business_rules: {
            danger: { threshold: 10.01 },
            warning: { min: 5.01, max: 10 },
            success: { threshold: 5 }
        }
    },
    {
        category: "minor",
        label: "Blemishes",
        value: "blemishes",
        business_rules: {
            danger: { threshold: 10.01 },
            warning: { min: 5.01, max: 10 },
            success: { threshold: 5 }
        }
    },
    {
        category: "internal",
        label: "Breakdown",
        value: "breakdown",
        business_rules: {
            danger: { threshold: 5.01 },
            warning: { min: 4.01, max: 5 },
            success: { threshold: 4 }
        }
    },
    {
        category: "internal",
        label: "Chill damage / glassiness / translucency",
        value: "chill-damage-/-glassiness-/-translucency",
        business_rules: {
            danger: { threshold: 5.01 },
            warning: { min: 4.01, max: 5 },
            success: { threshold: 4 }
        }
    }
];


// Function to truncate a text
export const truncateText = (text, maxLength = 32, truncateLength = 30, truncationIndicator = "...") => {
    if (text.length > maxLength) {
        return `${text.substring(0, truncateLength)}${truncationIndicator}`;
    }
    return text;
};
export enum ReportType {
    Intake = "intake",
    ART = "art",
    Reassessment = "reassessment",
}

