/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useRef, useState } from "react";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import { useSelector } from "react-redux";
import { Badge } from "reactstrap";
import { toast } from "../../utils/toast";
import apiClient from "../../constants/apiClient";
import { ROOT_URL } from "../../constants/urls";


const formatOptionLabel = (props) => {
    const { label, meta } = props;
    return (
        <div className="py-1">
            <div><b>{label}</b></div>
            {meta.length > 0 ? <div>
                <div>
                    {meta.map((item, index) => (
                        <div key={index}>
                            <Badge size="sm" className="me-1">{item.key}</Badge>
                            <Badge size="sm" className="me-1"> [ {item.value} ] </Badge>
                        </div>
                    ))}
                </div>
            </div> : null}
        </div>
    );
};

/**
 * @deprecated
 * Mehadrin specific component
 * Should be replaced by the LayerAutoComplete which is already giving support for multi
 */
export default function LayerAutoCompleteMulti({
    setValue, post_value, field, disabled, creatable, type, isMulti, meta_options
}) {
    const timer = useRef(null);
    const layer = useSelector((state) => state.layers.current);
    const [layer_meta, setLayerMeta] = useState([]);

    const searchLayers = (value, callback) => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            apiClient
                .get(`${ROOT_URL}api/layers/search/`, {
                    params: {
                        text: value,
                        // fruit_type: layer.fruit_type,
                        layer_type: type,
                        parent_layer_id: layer.id,
                    }
                })
                .then((response) => {
                    callback(response.data.map((i) => ({
                        value: i.label, label: i.label, meta: i.meta.filter((i) => meta_options.includes(i.key)).map((j) => ({ key: j.key, value: j.value }))
                    })));
                })
                .catch((error) => toast.error(`Could not fetch layers ${error}`));
        }, 350);
    };
    const _onLayerSelect = (values) => {
        let selectedValue;
        if (isMulti) {
            selectedValue = values ? values.map((i) => i.value).join(",") : "";
        } else {
            selectedValue = values ? values.value : "";
        }
        setValue(field, selectedValue);
        setLayerMeta(values.meta);
    };

    let value;
    if (isMulti) {
        value = (post_value || "").split(",").filter((i) => i).map((value) => ({ value, label: value }));
    } else {
        value = post_value ? { value: post_value, label: post_value, meta: layer_meta } : null;
    }

    if (creatable) {
        return (
            <AsyncCreatableSelect
                defaultOptions={[]}
                isMulti={isMulti}
                loadOptions={searchLayers}
                isClearable={false}
                onChange={_onLayerSelect}
                isDisabled={disabled}
                value={value}
            />
        );
    }

    return <AsyncSelect
        defaultOptions={[]}
        formatOptionLabel={formatOptionLabel}
        isMulti={isMulti}
        loadOptions={searchLayers}
        isClearable={false}
        onChange={_onLayerSelect}
        isDisabled={disabled}
        value={value}
    />;
}
LayerAutoCompleteMulti.defaultProps = {
    overwrite: true,
    creatable: true,
    post_value: "",
    type: null,
};
LayerAutoCompleteMulti.propTypes = {
    setValue: PropTypes.func,
    field: PropTypes.string,
    disabled: PropTypes.bool,
    isMulti: PropTypes.bool,
    meta_options: PropTypes.array,
    // overwrite: PropTypes.bool,
    creatable: PropTypes.bool,
    post_value: PropTypes.string,
    // type is used for searching
    type: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
};

