import { faFilter } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";
import useConfig from "../../../actions/Tenants/config/configHook";
import MetaForm from "../../Forms/MetaForm";

export interface LayerFilterProps {
    filter: any;
    setFilterItem: (field: string, value: any) => void;
    onReset: () => void;
}

export default function LayerFilter({ filter, setFilterItem, onReset }: LayerFilterProps) {
    const config = useConfig();
    const [isOpen, setModal] = useState(false);
    const stageConfig = config.get_stage(filter.location);
    const fields = stageConfig?.overview_filter_form?.map((i) => i.field) || [];
    const fields_not_fruit_type = fields.filter((i) => i.name !== "fruit_type");
    const filter_in_use = fields_not_fruit_type.some((field) => filter[field.name] !== undefined);
    return <div className="d-flex align-items-center justify-content-between">
        {fields_not_fruit_type && <>
            <Button color={filter_in_use ? "primary" : "light"} onClick={() => setModal(true)}>
                <FontAwesomeIcon icon={faFilter} />
            </Button>
        </>}
        <Modal size="md" isOpen={isOpen} toggle={() => setModal(false)} >
            <ModalHeader toggle={() => setModal(false)} >Filter</ModalHeader>
            <ModalBody>
                <MetaForm
                    meta={fields}
                    setValue={setFilterItem}
                    object={filter}
                    config={config}
                />
            </ModalBody>
            <ModalFooter>
                <Button color="light" onClick={() => setModal(false)}>Close</Button>
                <Button color="warning" outline={!filter_in_use} disabled={!filter_in_use} onClick={() => onReset()}>Reset</Button>
            </ModalFooter>
        </Modal >
    </div>;
}
