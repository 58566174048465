import { getStore } from "../../store";
import { convert_to_comma_seperated_list } from "../util";

import {
    CREATE, GET,
    LIST,
    UPDATE,
} from "./actionsTypes";

const endpoint = "packing-list/";

export function listPackingList(filter = {}) {
    const state = getStore().getState();
    filter = { ...state.packingLists.filter, ...filter };
    return {
        types: [LIST.LOADING, LIST.SUCCESS, LIST.ERROR],
        payload: {
            request: {
                filter_object: filter,
                params: filter_to_params(filter),
                method: "GET",
                url: endpoint,
            },
        },
    };
}

function filter_to_params(filter) {
    const meta = [
        "status",
    ];
    meta.forEach((i) => {
        filter = { ...filter, [i]: convert_to_comma_seperated_list(filter[i], i) };
    });
    return filter;

}

export function getPackingList() {
    return {
        types: [GET.LOADING, GET.SUCCESS, GET.ERROR],
        payload: {
            request: {
                method: "GET",
                url: endpoint,
            },
        },
    };
}

export function updatePackingListStep(step) {
    return {
        type: UPDATE.STEP,
        payload: step,
    };
}

export function updatePackingListImportData(data) {
    return {
        type: UPDATE.IMPORT_DATA,
        payload: data,
    };
}

export function previewPackingList(data) {
    return {
        types: [CREATE.LOADING, CREATE.SUCCESS, CREATE.ERROR],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint}preview`,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                data,
            },
        },
    };
}

export function importPackingList(list_id, data) {
    return {
        types: [CREATE.LOADING, CREATE.SUCCESS, CREATE.ERROR],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint + list_id}/import`,
                data,
            },
        },
    };
}

export function resetPreviewPackingList() {
    return {
        type: CREATE.RESET,
    };
}

export function reviewPackingList(list_id) {
    return {
        types: [CREATE.LOADING, CREATE.SUCCESS, CREATE.ERROR],
        payload: {
            request: {
                method: "GET",
                url: `${endpoint + list_id}/review`,
            },
        },
    };
}


export function updatePackingList(data) {
    return {
        types: [UPDATE.LOADING, UPDATE.SUCCESS, UPDATE.ERROR],
        payload: {
            request: {
                method: "PUT",
                url: `${endpoint + data.id}/`,
                data,
            },
        },
    };
}

