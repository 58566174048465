
/** @jsxImportSource @emotion/react */
import { DEFECT_POSITION, DEFECT_SEVERITY } from "../../../../actions/Checks/constants";
import ColorCodeTriggerForm from "./ColorCodeTriggerForm";
import { getConfiguredCheckMetaFieldNames, getConfiguredFruitFieldNames, getConfiguredLayerFieldNames } from "./CopyMetaConfigForm";
import FilterableConfigList from "./FilterableConfigList";
import FlagConfigForm, { flagOptions } from "./FlagConfigForm";

const flagMethods = {
    flag_fruit__fruit_field: "flag_fruit__fruit_field",
    flag_check__fruit_field: "flag_check__fruit_field",
    flag_check__defects: "flag_check__defects",
    flag_check__check_field: "flag_check__check_field",
    flag_check__fruit_flags: "flag_check__fruit_flags",
    flag_layer__check_flags: "flag_layer__check_flags",
    flag_layer__layer_field: "flag_layer__layer_field",
    flag_layer__date_diff: "flag_layer__date_diff",
    flag_parent_layers__layer_flags: "flag_parent_layers__layer_flags",
};


const calculationOptions = {
    [flagMethods.flag_check__fruit_field]: [
        {
            value: "underweight",
            label: "Underweight fruit based on reference weight"
        },
        {
            value: "average",
            label: "Average"
        },
        {
            value: "min",
            label: "Minimum value"
        },
        {
            value: "max",
            label: "Maximum value"
        },
        {
            value: "sum",
            label: "Sum all values"
        },
        {
            value: "median",
            label: "Median of all values"
        },
        {
            value: "count_unique_classes",
            label: "Count unique classes"
        },
        {
            value: "count_below_treshold",
            label: "Count below treshold"
        },
        {
            value: "count_above_treshhold",
            label: "Count above treshhold"
        },
    ],
};


const defaultField = {
    field: {
        text: "Flag check based on defects",
        flag_method: flagMethods.flag_check__defects,
    },
    display_filter: {
        fruit_types: [],
        exclude_fruit_types: [],
        layer_types: [],
        // exclude_layer_types: [],
    }
};

const flagMethodShortDescription = (flagMethod) => {
    if (!flagMethod) {
        return "";
    }
    const items = flagMethod.split("_");
    if (items.length === 1) {
        return items[0];
    }
    return `${items[0]} ${items[1]}`;
};

export default function ColorCodeMetricForm({ name, title, fields, description, setArray }) {
    const checkNames = getConfiguredCheckMetaFieldNames();
    const fruitNames = getConfiguredFruitFieldNames();
    const layerNames = getConfiguredLayerFieldNames(true);
    const getFieldTitle = (object) => <span><b>{flagMethodShortDescription(object.flag_method)}:</b> {object.text} [{object.flag_name}]</span>;
    const getFieldForm = (object, setObject) => {


        return [
            {
                type: "element",
                el: <div className="pt-3">
                    <ColorCodeTriggerForm
                        description={"When should this rule be applied? Disable to inherit from this scheme"}
                        object={object.trigger || {}}
                        setObject={(trigger) => setObject({ ...object, trigger })} />
                </div>
            },
            {
                type: "header",
                label: "Metric",
            },
            // {
            //     label: "Predefined metric",
            //     type: "single-select",
            //     name: "predefined_metric",
            //     description: "Select a predefined metric to use as template.",
            //     options: predefinedMetrics.map((i) => ({ label: i.text, value: i.predefined_metric })),
            //     onchange_callback: ({ field, value }) => {

            //         const predfinedField = predefinedMetrics.find((i) => i.predefined_metric === value);

            //         if (!predfinedField) {
            //             setObject(defaultField);
            //             return;
            //         }

            //         const newObject = Object.keys(predfinedField).reduce((acc, key) => {
            //             const attr = predfinedField[key];
            //             // * copy all serialiazable values
            //             if (!attr || typeof attr === "function") {
            //                 return acc;
            //             }
            //             return ({
            //                 ...acc,
            //                 [key]: predfinedField[key]
            //             });

            //         }, { ...object, [field]: value });
            //         setObject(newObject);

            //     }
            // },
            {
                label: "Method",
                type: "single-select",
                description: "Select the flag method",
                name: "flag_method",
                options: [
                    {
                        value: flagMethods.flag_fruit__fruit_field,
                        label: "Flag fruit based on fruit field ( e.g. pressure in spec)"
                    },
                    {
                        value: flagMethods.flag_check__fruit_field,
                        label: "Flag check based on aggregated fruit field ( e.g. avg brix)"
                    },
                    {
                        value: flagMethods.flag_check__defects,
                        label: "Flag check based on defects"
                    },
                    {
                        value: flagMethods.flag_check__fruit_flags,
                        label: "Flag check based on fruit flags"
                    },
                    {
                        value: flagMethods.flag_check__check_field,
                        label: "Flag check based on check field"
                    },
                    {
                        value: flagMethods.flag_layer__check_flags,
                        label: "Flag layer (e.g. pallet) based on check flags"
                    },
                    {
                        value: flagMethods.flag_parent_layers__layer_flags,
                        label: "Flag layer (e.g PO) based on layer flags"
                    },
                    {
                        value: flagMethods.flag_layer__layer_field,
                        label: "Flag layer based on layer field"
                    },
                    {
                        value: flagMethods.flag_layer__date_diff,
                        label: "Flag layer based on date difference"
                    },


                ]
            },
            {
                label: "Label",
                type: "text",
                description: "Give a short recognizable label for this metric.",
                name: "text",
            },
            [flagMethods.flag_check__defects].includes(object.flag_method) && {
                label: "Defect position",
                name: "defect_position",
                type: "single-select",
                return_array: true,
                multi: true,
                options: Object.values(DEFECT_POSITION).map((i) => ({ value: i, label: i })),
                onchange_callback: ({ field, value }) => {
                    const metric_meta_key = ["defects", ...(value || []), ...(object.defect_severity || [])].join("_");
                    const flag_name = `flag_${metric_meta_key}`;
                    setObject({ ...object, [field]: value, metric_meta_key, flag_name });
                }
            },
            [flagMethods.flag_check__defects].includes(object.flag_method) && {
                label: "Defect Severity",
                name: "defect_severity",
                type: "single-select",
                return_array: true,
                multi: true,
                options: Object.values(DEFECT_SEVERITY).map((i) => ({ value: i, label: i })),
                onchange_callback: ({ field, value }) => {
                    const metric_meta_key = ["defects", ...(object.defect_position || []), ...(value || [])].join("_");
                    const flag_name = `flag_${metric_meta_key}`;
                    setObject({ ...object, [field]: value, metric_meta_key, flag_name });
                }
            },

            [flagMethods.flag_check__fruit_field, flagMethods.flag_fruit__fruit_field].includes(object.flag_method) && {
                label: "Field",
                type: "single-select",
                description: "Which field to read from.",
                name: "field",
                options: fruitNames.map((i) => ({ label: i, value: i })),
                onchange_callback: ({ field, value }) => {
                    const flag_name = `flag_${value}`;
                    setObject({ ...object, [field]: value, flag_name });
                }
            },
            flagMethods.flag_check__check_field === object.flag_method && {
                label: "Field",
                type: "single-select",
                description: "Which field to read from.",
                name: "field",
                options: checkNames.map((i) => ({ label: i, value: i })),
                onchange_callback: ({ field, value }) => {
                    const flag_name = `flag_${value}`;
                    setObject({ ...object, [field]: value, flag_name });
                }
            },
            flagMethods.flag_layer__layer_field === object.flag_method && {
                label: "Field",
                type: "single-select",
                description: "Which field to read from.",
                name: "field",
                options: layerNames.map((i) => ({ label: i, value: i })),
                onchange_callback: ({ field, value }) => {
                    const flag_name = `flag_${value}`;
                    setObject({ ...object, [field]: value, flag_name });
                }
            },
            [flagMethods.flag_check__fruit_field, flagMethods.flag_fruit__fruit_field, flagMethods.flag_check__check_field].includes(object.flag_method) && {
                label: "Field",
                type: "text",
                description: "Your field not in the dropdown above? Type it here. e.g. pressure, brix, weight etc.",
                name: "field",
            },
            calculationOptions?.[object.flag_method] && {
                label: "Calculation",
                type: "single-select",
                name: "calculation",
                options: calculationOptions?.[object.flag_method]

            },
            // * Calculation options
            ["count_below_treshold", "count_above_treshhold"].includes(object.calculation) && {
                label: "Treshold",
                type: "number",
                name: "treshold",
            },
            flagMethods.flag_layer__date_diff === object.flag_method && {
                label: "From date",
                type: "single-select",
                name: "from_date",
                options: layerNames.map((i) => ({ label: i, value: i }))
            },
            flagMethods.flag_layer__date_diff === object.flag_method && {
                label: "To date",
                type: "single-select",
                name: "to_date",
                options: layerNames.map((i) => ({ label: i, value: i }))
            },
            [flagMethods.flag_check__fruit_flags, flagMethods.flag_layer__check_flags, flagMethods.flag_parent_layers__layer_flags].includes(object.flag_method) && {
                label: "Source flag name",
                type: "text",
                // eslint-disable-next-line max-len
                description: <span>Which flag name to count e.g flag_defects, flag_ripening, flag_average_maturity, intake_status, ripening_status etc.</span>,
                name: "source_flag_name",
            },
            [flagMethods.flag_check__fruit_flags, flagMethods.flag_layer__check_flags, flagMethods.flag_parent_layers__layer_flags].includes(object.flag_method) && {
                label: "Count flag colors",
                type: "single-select",
                name: "count_flag_colors",
                description: "Count the number of flags with a specific color.",
                multi: true,
                options: flagOptions,
                return_array: true
            },
            [flagMethods.flag_check__fruit_field, flagMethods.flag_check__fruit_flags, flagMethods.flag_check__defects, flagMethods.flag_layer__date_diff].includes(object.flag_method) && {
                label: "Meta key for absolute value",
                type: "text",
                description: <span>Under which key to store the absolute metric in the meta data. e.g. will store `&#123;check_location&#125;_{object.metric_meta_key}` and `{object.metric_meta_key}` in layer_meta and  `{object.metric_meta_key}` on check_meta. Leave empty to not store the metric.</span>,
                name: "metric_meta_key",
            },
            {
                label: "Relative values / Absolute values",
                type: "radio",
                name: "relative_absolute",
                description: "Compare against actual/absolute or relative values? When set to relative use a value between 0 and 1 in your flags config and select the proper denominator.",
                options: [
                    {
                        value: "relative",
                        label: "Relative values"
                    },
                    {
                        value: "absolute",
                        label: "Absolute values"
                    }
                ]
            },
            object.relative_absolute === "relative" && {
                label: "Denominator",
                type: "single-select",
                name: "denominator",
                description: <span>The denominator to use for relative calculations. The relative value will be stored under `&#123;check_location&#125;_{object.metric_meta_key}_relative` and `{object.metric_meta_key}_relative` in layer_meta and  `{object.metric_meta_key}_relative` on check_meta.</span>,
                options: [
                    {
                        value: "sample_size",
                        label: "Sample size"
                    },
                    {
                        value: "avos_fruit",
                        label: "Avos fruit"
                    },
                    {
                        value: "layer_children",
                        label: "Layer children"
                    },
                    {
                        value: "reference_weight_for_box_size",
                        label: "Lower bound reference weight for box size"
                    },
                ]
            },
            {
                type: "header",
                label: "Flags",
            },
            {
                label: "Target flag name",
                type: "text",
                // eslint-disable-next-line max-len
                description: <span>Flag name e.g flag_defects, flag_ripening,  flag_average_maturity etc. The flag color will be stored as `&#123;check_location&#125;_{object.flag_name}` and `{object.flag_name}` in layer_meta and  `{object.flag_name}` on check meta. Reusing the same flag name will create an array of flags colors and use the most negative color as final flag. If method is set to &quot;Flag fruit based on fruit field&quot; the flag is only set on fruit_meta.</span>,
                name: "flag_name",
            },
            {
                label: "Default flag",
                type: "single-select",
                name: "default_flag",
                options: [...flagOptions, { value: "no-update", label: "No Update" }]

            },
            {
                type: "element",
                el: <FlagConfigForm
                    fields={object.flags || []}
                    description="Possible flags for this metric. For relative metrics use a value between 0 and 1. The order matters, the first flag that matches the condition will be used."
                    name="flags"
                    title="Flags"
                    setArray={(flags) => setObject({ ...object, flags })} />
            }
        ].filter((i) => i); // filter out undefined


    };

    const fields_checked_for_error = fields.map((i) => ({ ...i, error: !flagMethods[i.flag_method] }));
    const has_undefined_field = fields_checked_for_error.some((i) => i.error);
    const error = has_undefined_field ? "Some fields are using deprecated methods or are not properly defined." : false;

    return <FilterableConfigList
        name={name}
        title={title}
        description={description}
        fields={fields_checked_for_error}
        error={error}
        setArray={setArray}
        defaultField={defaultField}
        getFieldForm={getFieldForm}
        getFieldTitle={getFieldTitle}
        useDisplayFilter={false}
    />;

}


