/** @jsxImportSource @emotion/react */

import { headerLinks } from "../../../../actions/Tenants/config/constants";
import FilterableConfigList from "./FilterableConfigList";


export const links = [
    { path: headerLinks.home, label: "Overview" },
    { path: headerLinks.settings, label: "Settings" },
    { path: headerLinks.logout, label: "Logout" },
    { path: headerLinks.insights, label: "Insights" },
    { path: headerLinks.labelling, label: "Labelling" },
    { path: headerLinks.packinglist, label: "Packing list" },
    { path: headerLinks.deviceManagement, label: "Device Management" },
    { path: headerLinks.tenants, label: "Tenants" },
    { path: headerLinks.ripening, label: "Ripening" },
    { path: headerLinks.waste, label: "Waste" },
    // { path: headerLinks.stopTest, label: "~hardcoded~" },
    { path: headerLinks.mailbox, label: "Mailbox" },
];

const defaultField = {
    field: {
        path: headerLinks.home,
        label: "Overview",
    },
    display_filter: {
        fruit_types: [],
        exclude_fruit_types: [],
        layer_types: [],
    // exclude_layer_types: [],
    }
};
export default function NavigationConfigForm({ name, title, fields, description, setArray }) {
    const getFieldTitle = (object) => <span>{object.label} [{object.path}]</span>;
    const getFieldForm = (_, setObject) => [
        {
            label: "Path",
            type: "single-select",
            name: "path",
            options: links.map((i) => ({ value: i.path, label: i.label })),
            onchange_callback: ({ value }) => {
                const urlOption = links.find((i) => i.path === value);
                if (urlOption) {
                    setObject(urlOption);
                } else {
                    setObject({ path: null, label: "" });
                }

            }
        },
        {
            label: "Label",
            type: "text",
            name: "label",
        }

    ];
    return <FilterableConfigList
        name={name}
        title={title}
        description={description}
        fields={fields}
        setArray={setArray}
        defaultField={defaultField}
        getFieldForm={getFieldForm}
        getFieldTitle={getFieldTitle}
        useDisplayFilter={false}
    />;

}


