import { useDispatch } from "react-redux";
import { useDebounce } from "../../Forms/utils";
import { listLayers, updateFilter } from "../../../actions/Layers/actions";
import { LayerFilter } from "../../../actions/Layers/constants";

export const useListLayersDebounced = (onDebounced?: (filter: LayerFilter) => void) => {
    const dispatch = useDispatch<any>();
    return useDebounce(
        (filter: LayerFilter) => dispatch(updateFilter(filter)),
        (filter: LayerFilter) => {
            if (onDebounced) {
                onDebounced(filter);
            } else {
                dispatch(listLayers(filter));
            }
        },
        { debounceTime: 500 }
    );
};
