import { toast } from "../../utils/toast";
import { AvosAction } from "../Layers/actions";
import { convert_to_comma_seperated_list } from "../util";
import {
    CREATE, DELETE, GET,
    LIST,
    PATCH,
    RESET,
    UPDATE,
} from "./actionsTypes";
import { colors, defaultAvocado } from "./constants";
import { formatFruit2Backend } from "./utils";

const endpoint = "avocados/";

export function listAvocados() {
    return {
        types: [LIST.LOADING, LIST.SUCCESS, LIST.ERROR],
        payload: {
            request: {
                method: "GET",
                url: endpoint,
            },
        },
    };
}

export function getAvocado() {
    return {
        types: [GET.LOADING, GET.SUCCESS, GET.ERROR],
        payload: {
            request: {
                method: "GET",
                url: endpoint,
            },
        },
    };
}
export function createAvocado(data) {
    data = formatFruit2Backend(data);
    return {
        types: [CREATE.LOADING, CREATE.SUCCESS, CREATE.ERROR],
        payload: {
            request: {
                method: "POST",
                url: endpoint,
                data,
            },
        },
    };
}

export function createManualAvocado(data) {
    data = formatFruit2Backend(data);
    return {
        types: [CREATE.LOADING, CREATE.SUCCESS, CREATE.ERROR],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint}manual/`,
                data,
            },
        },
    };
}

export function createDuplicateAvocado(fruit_id) {
    return {
        types: [CREATE.LOADING, CREATE.SUCCESS, CREATE.ERROR],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint}${fruit_id}/duplicate/`,
            },
        },
    };
}

export function resetAvocadoState() {
    const data = { ...defaultAvocado };

    return {
        type: RESET.SUCCESS,
        payload: { data },
    };
}
export function patchAvocado(avocado_id, field, value, callApi = true) {
    // only update the field in the store
    if (!callApi) {
        return {
            type: PATCH.STATE,
            payload: { avocado_id, field, value },
        };
    }
    const data = {
        avocado_id,
        field,
        state_value: value,
        value: convert_to_comma_seperated_list(value)
    };

    return {
        types: [PATCH.LOADING, PATCH.SUCCESS, PATCH.ERROR],
        payload: {
            request: {
                method: "PATCH",
                url: `${endpoint + avocado_id}/`,
                data,
            },
        },
    };
}

export function updateAvocado(data) {
    data = formatFruit2Backend(data);
    return {
        types: [UPDATE.LOADING, UPDATE.SUCCESS, UPDATE.ERROR],
        payload: {
            request: {
                method: "PUT",
                url: `${endpoint + data.avocado_id}/`,
                data,
            },
        },
    };
}

export function postDefect(data) {
    return {
        types: [UPDATE.LOADING, UPDATE.SUCCESS, UPDATE.ERROR],
        payload: {
            request: {
                method: "POST",
                url: `defects/register`,
                data
            },
        },
    };
}

export interface RegisterDefectsParams {
    test_id: number;
    fruit_id: number;
    defect_ids: string[];
    unregister_others?: boolean;
    positions: string[];
    severities: string[];
}

export function registerDefects(data: RegisterDefectsParams) {
    return {
        types: [UPDATE.LOADING, UPDATE.SUCCESS, UPDATE.ERROR],
        payload: {
            request: {
                method: "POST",
                url: `defects/register-bulk`,
                data: {
                    ...data,
                    unregister_others: data.unregister_others || true,
                }
            },
        },
    } as unknown as AvosAction;
}

export function deleteAvocado() {
    return {
        types: [DELETE.LOADING, DELETE.SUCCESS, DELETE.ERROR],
        payload: {
            request: {
                method: "DELETE",
                url: endpoint,
            },
        },
    };
}

export function handlePressureValues(avocado) {
    const avocadoPressIsEmpty = (!(avocado.pressure === 0 || avocado.pressure > 0) || avocado.pressure === null);
    if (avocadoPressIsEmpty) {
        if (avocado.pressure_2 > 0 && avocado.pressure_1 > 0) {
            avocado.pressure = ((parseFloat(avocado.pressure_1) + parseFloat(avocado.pressure_2)) / 2).toFixed(2);
        }
        if (!avocado.pressure_2 && avocado.pressure_1) {
            avocado.pressure = `${avocado.pressure_1}`;
        }
        if (!avocado.pressure_1 && avocado.pressure_2) {
            avocado.pressure = `${avocado.pressure_2}`;
        }
    }
    return avocado;
}

export function validateAvocado(avocado) {
    if (!colors.some((i) => i.value === avocado.manual_color_stage)) {
        toast.warning("Please select a color stage!", {
            toastId: "invalid-avocado",
            autoClose: 1500,
        });
        return false;
    }

    const avocadoPressIsEmpty = (!(avocado.pressure === 0 || avocado.pressure > 0) || avocado.pressure === null);
    if (avocadoPressIsEmpty) {
        toast.warning("Please fill in the pressure!", {
            toastId: "invalid-avocado",
            autoClose: 1500,
        });
        return false;
    }
    if (avocado.defects.length > 0 && !avocado.image && !avocado.images.find((i) => i.type === "manual")) {
        toast.warning("Please take a picture of the defects.", {
            toastId: "invalid-avocado",
            autoClose: 1500,
        });
        return false;
    }
    return true;
}
