const platformKeys = [
    "agent",
    "estimated_date_of_arrival",
    "article_name",
    "batch",
    "batch_code",
    "bill_of_loading_number",
    "bio",
    "booking",
    "booking_number",
    "box_condition",
    "box_size",
    "box_trace",
    "box_weight",
    "brand",
    "ca_technology",
    "caliber",
    "class",
    "client",
    "co2_setting",
    "coc_number",
    "company",
    "consignee",
    "container",
    "container_class",
    "container_co2",
    "container_ethylene",
    "container_humidity",
    "container_no",
    "container_o2",
    "container_temp",
    "container_temperature",
    "color",
    "country",
    "customer",
    "customer_reference_number",
    "customs_seal",
    "date_code",
    "departure_date",
    "date_of_loading",
    "date_receipt",
    "delivery",
    "depot_date",
    "description",
    "destination",
    "dispatch_date",
    "dispatch_number",
    "dry_matter",
    "due_date",
    "end_client",
    "end_pallet",
    "estimated_date_of_arrival",
    "eta",
    "eur1_number",
    "expected_day_out",
    "exporter",
    "field",
    "fruit_temperature",
    "fruit_type",
    "fruit_variety",
    "global_gap_number",
    "global_gap_number_supplier",
    "grower",
    "grower_code",
    "grower_name",
    "harvest_date",
    "humidity_setting",
    "incoterms",
    "inspection_date",
    "integrity_seal",
    "insurance",
    "invoice_number",
    "julian_code",
    "label",
    "license_plate",
    "line_seal",
    "loading_port",
    "loading_date",
    "loading_week",
    "location",
    "logger_temperature",
    "logger_temperature_radio",
    "lot_number",
    "net_weight",
    "net_weight_kg",
    "number_of_fruits",
    "number_of_boxes",
    "number_of_pallets",
    "o2_setting",
    "operator",
    "order_number",
    "pack_date",
    "packing",
    "packaging",
    "packing_date",
    "packing_house",
    "packing_list_number",
    "packing_seal",
    "pallet",
    "pallet_id",
    "pallet_bruto_weight",
    "pallet_neto_weight",
    "supplier_pallet_number",
    "phyto_number",
    "port_of_arrival",
    "port_of_departure",
    "produced_date",
    "product",
    "product_description",
    "production_date",
    "purchase_order_number",
    "quantity",
    "receipt",
    "sales_order",
    "sensors_locations",
    "shipping_line",
    "size",
    "snapshot_datetime",
    "sup_ref",
    "supplier",
    "supplier_label",
    "supplier_pallet_number",
    "temperature_setting",
    "temptale_number",
    "temptale_type",
    "terminal_location",
    "thermographs",
    "time_arrival",
    "time_departure",
    "tnt_code",
    "total_kg",
    "transfer",
    "unit",
    "vent_open_close",
    "ventilation_setting",
    "vessel",
    "vessel_n",
    "vessel_number",
    "vessel_name",
    "voyage_number",
    "vendor_name",
    "farm",
    "orchard",
    "number_of_boxes",
    "temptale_number",
    "traceability_code",
    "yield",
];

export const layerFormFields = [...platformKeys.map((i) => ({
    form_field_id: `layer_${i}`,
    label: i.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase()),
    name: i,
    type: "text"
})),
{
    form_field_id: "intake_manual_flag",
    label: "Intake Manual Flag",
    name: "intake_manual_flag",
    type: "radio-flag"
},
{
    form_field_id: "advance_ripening_manual_flag",
    label: "ART Manual Flag",
    name: "advance_ripening_manual_flag",
    type: "radio-flag"
}

];
