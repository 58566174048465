import { Html5QrcodeSupportedFormats } from "html5-qrcode";
import {
    baseConfig,
    headerLinks,
    pages,
    path_fta_input,
    ripening_colors,
    ripening_stages_firmness,
} from "../constants";
import { RootConfigType } from "../constantsTyped";
import { createBarcodeFilter } from "../utils";

const ripening_stages = [
    {
        gte: false,
        lt: 0.5,
        label: "Soft",
        color: ripening_colors.Soft,
    },
    {
        gte: 0.5,
        lt: 1.3,
        label: "RTE",
        color: ripening_colors.RTE,
    },
    {
        gte: 1.3,
        lt: 2.4,
        label: "RTE+",
        color: ripening_colors["RTE+"],
    },
    {
        gte: 2.4,
        lt: 3.6,
        label: "Triggered",
        color: ripening_colors.Triggered,
    },
    {
        gte: 3.6,
        lt: 6.1,
        label: "Triggered+",
        color: ripening_colors["Triggered+"],
    },
    {
        gte: 6.1,
        lt: false,
        label: "Hard",
        color: ripening_colors.Hard,
    },
];

const required_fruit_images = [
    {
        type: "fruit_overview",
        label: "Fruit",
    },
];


export const BakkerConfig: RootConfigType = {
    ...baseConfig,
    ripening_stages,
    ripening_stages_firmness,
    summary_collapsed: false,
    required_fruit_images,
    ripening_stages_pressure: ripening_stages,
    research_mode: true,
    summary_like_question: "Do you want to flag this check?",

    pages: { ...pages, [path_fta_input]: "FTA Input" },
    lang: { ...pages, pressure: "Pressure" },
    show_device_toggle: false,
    barcode_filter: {
        filter: createBarcodeFilter([
            // This regex is for any barcode format starting with any 11 digits, then {8} as the pallet number, followed by any digit
            /^\d{11}(\d{8})\d$/,
        ]),
        formatsToSupport: [Html5QrcodeSupportedFormats.CODE_128]
    },
    headerMainLinks: [headerLinks.home, headerLinks.packinglist],
    headerSecondaryLinks: [headerLinks.packinglist, headerLinks.settings, headerLinks.logout],
};
