/** @jsxImportSource @emotion/react */
import { faDownToLine } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";
import { addLayerLocationHistory, getLayer } from "../../../../actions/Layers/actions";
import { LAYER_STAGE } from "../../../../actions/Layers/constants";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { RadioButtons } from "../../../Forms/RadioButton";

export default function AllocateStageModal() {
    const config = useConfig();
    const layer = useSelector((state) => state.layers.current);
    const params = useParams();

    const [state, setstate] = useState({
        isOpen: params.action === "allocate",
        stage: ""
    });

    const current_stage = layer.latest_location?.stage;

    const dispatch = useDispatch();

    const addLocation = (stage) => {
        if (stage === current_stage || !stage) {
            return;
        }
        setstate({ ...state, stage });
        dispatch(addLayerLocationHistory({ layer_id: layer.id, stage })).then(() => dispatch(getLayer(layer.id)));
        setstate({
            ...state,
            isOpen: false,
        });
    };
    const stages = config.stages.filter((i) => i.value).map((i) => ({ ...i, color: i.value === LAYER_STAGE.IN_TRANSIT ? "warning" : "secondary" }));

    const is_disabled = stages.length < 2 || stages[stages.length - 1].value === current_stage;
    const layer_type = config.get_layer_config(layer);

    return <>
        {
            layer_type.show_allocate_button && <div key={0} className="pe-2">
                <Button
                    size="sm"
                    color="secondary" outline
                    className="text-nowrap mb-2"
                    disabled={is_disabled}
                    onClick={() => setstate({
                        isOpen: true
                    })}>Allocate<FontAwesomeIcon icon={faDownToLine} /></Button>
            </div>
        }
        <Modal key={1} isOpen={state.isOpen} size="md">
            <ModalHeader >Allocation</ModalHeader>
            <ModalBody>
                <RadioButtons color="secondary" current={current_stage} options={stages} onChange={(_, value) => addLocation(value)}></RadioButtons>
            </ModalBody>
            <ModalFooter>
                <Button color="light" onClick={() => setstate({ ...state, isOpen: false })}>Cancel</Button>
            </ModalFooter>
        </Modal>
    </>;
}
