// TODO: remove this file after clean up configs

import { DEFAULT_FRUIT, AVOCADO_FRUIT, MANGO_FRUIT, KIWI_FRUIT, PEACH_FRUIT, NECTARINE_FRUIT, PLUM_FRUIT, PEAR_FRUIT, APPLE_FRUIT, GRAPE_FRUIT } from "./fruitNames";
import Field from "../../../components/scenes/Layers/fields/Field";
import { LAYER } from "../../Layers/fields";


export interface ColorConfig {
    text?: string;
    value: number;
    color?: string;
}

export type FruitFirmness = {
    gte: number | boolean;
    lt: number | boolean;
    label: string;
    color: string;
};

export interface FruitConfig {
    default_variety: string;
    a_fruit?: string;
    n?: string;
    n_cap?: string;
    lang?: object // todo remove this
    internal_colors?: ColorConfig[];
    external_colors?: ColorConfig[];
    ripening_field?: Field; // Field to be used on firmness graph
    firmness_class: FruitFirmness[]; // Field for fruit type firmness class
}


export const avocado_colors_default: ColorConfig[] = [
    { text: "1", value: 1, color: "#057430" },
    { text: "2", value: 2, color: "#1C6327" },
    { text: "3", value: 3, color: "#32511D" },
    { text: "4", value: 4, color: "#4A4014" },
    { text: "5", value: 5, color: "#612F0A" },
];

export const mango_internal_colors_default: ColorConfig[] = [
    { text: "1", value: 1, color: "#f8d37a" },
    { text: "2", value: 2, color: "#f0d35b" },
    { text: "3", value: 3, color: "#e7bf43" },
    { text: "4", value: 4, color: "#f6b041" },
    { text: "5", value: 5, color: "#f19940" },
];


// 2 and 4 are need for the pointers in the atron results page, they are however not displayed as button for the manual input
export const avocado_colors_alternate_textual: ColorConfig[] = [
    { text: "Green", value: 1, color: "#057430" },
    { value: 2 },
    { text: "Turning", value: 3, color: "#32511D" },
    { value: 4 },
    { text: "Brown", value: 5, color: "#612F0A" },
];

export const mango_colors_alternate_textual: ColorConfig[] = [
    { text: "Green", value: 1, color: "#057430" },
    { value: 2 },
    { text: "Red-Green", value: 3, color: "#c56f09" },
    { value: 4 },
    { text: "Red", value: 5, color: "#ce4502" },
];

export const ripening_colors = {
    RTE: "#9A6804",
    Triggered: "#049510",
    Hard: "#015D09",
    "RTE+": "#885C02",
    "Triggered+": "#017F0B",
    Soft: "brown",
    Ripe: "#049510",
    "Nearly ripe": "#D9B300",
    Overripe: "#FFA500",
    Medium: "#049510",
    Firm: "#D9B300",
    Hard_Mission: "#D64550",
    Red: "#FF0000",
    Green: "#089000"
};

export const fruit_lang: { [key in FruitTypes]: object } = {
    [DEFAULT_FRUIT]: {
        a_fruit: "a fruit",
        n: "fruits",
        cap: "Fruit",
        n_cap: "Fruits",
        mini: "AVOS",
    },
    [APPLE_FRUIT]: {
        a_fruit: "a apple",
        n: "apple",
        cap: "Apple",
        n_cap: "Apples",
        mini: "APPLE",
    },
    [AVOCADO_FRUIT]: {
        a_fruit: "an avocado",
        n: "avocados",
        cap: "Avocado",
        n_cap: "Avocados",
        mini: "AVOS",
    },
    [MANGO_FRUIT]: {
        a_fruit: "a mango",
        n: "mangos",
        cap: "Mango",
        n_cap: "Mangos",
        mini: "MANGOS",
    },
    [KIWI_FRUIT]: {
        a_fruit: "a kiwi",
        n: "kiwis",
        cap: "Kiwi",
        n_cap: "Kiwis",
        mini: "KIWIS",
    },
    [PEACH_FRUIT]: {
        a_fruit: "a peach",
        n: "peaches",
        cap: "Peach",
        n_cap: "Peaches",
        mini: "PEACHES",
    },
    [NECTARINE_FRUIT]: {
        a_fruit: "a nectarine",
        n: "nectarines",
        cap: "Nectarine",
        n_cap: "Nectarines",
        mini: "NECTARINES",
    },
    [PLUM_FRUIT]: {
        a_fruit: "a plum",
        n: "plum",
        cap: "Plum",
        n_cap: "Plums",
        mini: "PLUM",
    },
    [PEAR_FRUIT]: {
        a_fruit: "a pear",
        n: "pear",
        cap: "Pear",
        n_cap: "Pears",
        mini: "PEARS",
    },
    [GRAPE_FRUIT]: {
        a_fruit: "a grape",
        n: "grapes",
        cap: "Grape",
        n_cap: "Grapes",
        mini: "GRAPES",
    }
};


export const default_fruit_config: FruitConfig = {
    default_variety: "",
    lang: fruit_lang[DEFAULT_FRUIT],
    external_colors: avocado_colors_alternate_textual,
    ripening_field: LAYER.AGGREGATE_FRUIT_ATRON_MEDIAN_AVG,
    firmness_class: [
        {
            gte: -100,
            lt: false,
            label: "No Fruit Type",
            color: ripening_colors.Overripe
        },
    ],
};

export const default_avocado_config: FruitConfig = {
    default_variety: "",
    lang: fruit_lang[AVOCADO_FRUIT],
    external_colors: avocado_colors_alternate_textual,
    ripening_field: LAYER.AGGREGATE_FRUIT_ATRON_MEDIAN_AVG,
    firmness_class: [
        {
            gte: false,
            lt: 10,
            label: "Overripe",
            color: ripening_colors.Overripe
        },
        {
            gte: 10,
            lt: 31,
            label: "Soft",
            color: ripening_colors.Soft
        },
        {
            gte: 31,
            lt: 50,
            label: "Medium",
            color: ripening_colors.Medium
        },
        {
            gte: 50,
            lt: 71,
            label: "Firm",
            color: ripening_colors.Firm
        },
        {
            gte: 71,
            lt: 200,
            label: "Hard",
            color: ripening_colors.Hard
        }
    ],
};

export const default_mango_config: FruitConfig = {
    default_variety: "",
    lang: fruit_lang[MANGO_FRUIT],
    internal_colors: mango_internal_colors_default,
    external_colors: mango_colors_alternate_textual,
    ripening_field: LAYER.AGGREGATE_FRUIT_ATRON_MEDIAN_AVG,
    firmness_class: [],
};

export const default_kiwi_config: FruitConfig = {
    default_variety: "",
    lang: fruit_lang[KIWI_FRUIT],
    internal_colors: mango_internal_colors_default,
    external_colors: mango_colors_alternate_textual,
    ripening_field: LAYER.AGGREGATE_FRUIT_ATRON_MEDIAN_AVG,
    firmness_class: [
        {
            gte: false,
            lt: 31,
            label: "Overripe",
            color: ripening_colors.Overripe
        },
        {
            gte: 31,
            lt: 51,
            label: "Soft",
            color: ripening_colors.Soft
        },
        {
            gte: 51,
            lt: 61,
            label: "Medium",
            color: ripening_colors.Medium
        },
        {
            gte: 61,
            lt: 71,
            label: "Firm",
            color: ripening_colors.Firm
        },
        {
            gte: 71,
            lt: false,
            label: "Hard",
            color: ripening_colors.Hard
        },
    ],
};

export const default_peach_config: FruitConfig = {
    default_variety: "",
    lang: fruit_lang[PEACH_FRUIT],
    internal_colors: mango_internal_colors_default,
    external_colors: mango_colors_alternate_textual,
    ripening_field: LAYER.AGGREGATE_FRUIT_ATRON_MEDIAN_AVG,
    firmness_class: [
        {
            gte: false,
            lt: 21,
            label: "Overripe",
            color: ripening_colors.Overripe
        },
        {
            gte: 21,
            lt: 31,
            label: "Soft",
            color: ripening_colors.Soft
        },
        {
            gte: 31,
            lt: 36,
            label: "Medium",
            color: ripening_colors.Medium
        },
        {
            gte: 36,
            lt: 46,
            label: "Firm",
            color: ripening_colors.Firm
        },
        {
            gte: 46,
            lt: false,
            label: "Hard",
            color: ripening_colors.Hard
        },
    ],
};

export const default_nectarine_config: FruitConfig = {
    default_variety: "",
    lang: fruit_lang[NECTARINE_FRUIT],
    internal_colors: mango_internal_colors_default,
    external_colors: mango_colors_alternate_textual,
    ripening_field: LAYER.AGGREGATE_FRUIT_ATRON_MEDIAN_AVG,
    firmness_class: [
        {
            gte: false,
            lt: 21,
            label: "Overripe",
            color: ripening_colors.Overripe
        },
        {
            gte: 21,
            lt: 31,
            label: "Soft",
            color: ripening_colors.Soft
        },
        {
            gte: 31,
            lt: 36,
            label: "Medium",
            color: ripening_colors.Medium
        },
        {
            gte: 36,
            lt: 46,
            label: "Firm",
            color: ripening_colors.Firm
        },
        {
            gte: 46,
            lt: false,
            label: "Hard",
            color: ripening_colors.Hard
        },
    ],
};

export const default_plum_config: FruitConfig = {
    default_variety: "",
    lang: fruit_lang[PLUM_FRUIT],
    internal_colors: mango_internal_colors_default,
    external_colors: mango_colors_alternate_textual,
    ripening_field: LAYER.AGGREGATE_FRUIT_ATRON_MEDIAN_AVG,
    firmness_class: [
        {
            gte: false,
            lt: 16,
            label: "Overripe",
            color: ripening_colors.Overripe
        },
        {
            gte: 16,
            lt: 26,
            label: "Soft",
            color: ripening_colors.Soft
        },
        {
            gte: 26,
            lt: 46,
            label: "Medium",
            color: ripening_colors.Medium
        },
        {
            gte: 46,
            lt: 56,
            label: "Firm",
            color: ripening_colors.Firm
        },
        {
            gte: 55,
            lt: false,
            label: "Hard",
            color: ripening_colors.Hard
        },
    ],
};

export const default_pear_config: FruitConfig = {
    default_variety: "",
    lang: fruit_lang[PEAR_FRUIT],
    internal_colors: mango_internal_colors_default,
    external_colors: mango_colors_alternate_textual,
    ripening_field: LAYER.AGGREGATE_FRUIT_ATRON_MEDIAN_AVG,
    firmness_class: [
        {
            gte: false,
            lt: 11,
            label: "Overripe",
            color: ripening_colors.Overripe
        },
        {
            gte: 11,
            lt: 31,
            label: "Soft",
            color: ripening_colors.Soft
        },
        {
            gte: 31,
            lt: 50,
            label: "Medium",
            color: ripening_colors.Medium
        },
        {
            gte: 50,
            lt: 71,
            label: "Firm",
            color: ripening_colors.Firm
        },
        {
            gte: 71,
            lt: 200,
            label: "Hard",
            color: ripening_colors.Hard
        },
    ],
};

export const default_apple_config: FruitConfig = {
    default_variety: "",
    lang: fruit_lang[APPLE_FRUIT],
    internal_colors: mango_internal_colors_default,
    external_colors: mango_colors_alternate_textual,
    ripening_field: LAYER.AGGREGATE_FRUIT_ATRON_MEDIAN_AVG,
    firmness_class: [
        {
            gte: false,
            lt: 11,
            label: "Overripe",
            color: ripening_colors.Overripe
        },
        {
            gte: 11,
            lt: 31,
            label: "Soft",
            color: ripening_colors.Soft
        },
        {
            gte: 31,
            lt: 50,
            label: "Medium",
            color: ripening_colors.Medium
        },
        {
            gte: 50,
            lt: 71,
            label: "Firm",
            color: ripening_colors.Firm
        },
        {
            gte: 71,
            lt: 200,
            label: "Hard",
            color: ripening_colors.Hard
        },
    ],
};


export const default_grape_config: FruitConfig = {
    default_variety: "",
    a_fruit: "a fruit",
    n: "fruits",
    n_cap: "Fruits",
    internal_colors: mango_internal_colors_default,
    external_colors: mango_colors_alternate_textual,
    ripening_field: LAYER.AGGREGATE_FRUIT_ATRON_MEDIAN_AVG,
    firmness_class: [
        {
            gte: false,
            lt: 16,
            label: "Overripe",
            color: ripening_colors.Overripe
        },
        {
            gte: 16,
            lt: 26,
            label: "Soft",
            color: ripening_colors.Soft
        },
        {
            gte: 26,
            lt: 46,
            label: "Medium",
            color: ripening_colors.Medium
        },
        {
            gte: 46,
            lt: 56,
            label: "Firm",
            color: ripening_colors.Firm
        },
        {
            gte: 55,
            lt: false,
            label: "Hard",
            color: ripening_colors.Hard
        },
    ],
};


export enum FruitTypes {
    AVOCADO_FRUIT = "avocado",
    MANGO_FRUIT = "mango",
    KIWI_FRUIT = "kiwi",
    PEACH_FRUIT = "peach",
    PLUM_FRUIT = "plum",
    NECTARINE_FRUIT = "nectarine",
    PEAR_FRUIT = "pear",
    DEFAULT_FRUIT = "fruit",
    APPLE_FRUIT = "apple",
    GRAPE_FRUIT = "grape"
}

export const fruit_types: { [key in FruitTypes]: FruitConfig } = {
    [DEFAULT_FRUIT]: default_fruit_config,
    [AVOCADO_FRUIT]: default_avocado_config,
    [MANGO_FRUIT]: default_mango_config,
    [KIWI_FRUIT]: default_kiwi_config,
    [PEACH_FRUIT]: default_peach_config,
    [NECTARINE_FRUIT]: default_nectarine_config,
    [PLUM_FRUIT]: default_plum_config,
    [PEAR_FRUIT]: default_pear_config,
    [APPLE_FRUIT]: default_apple_config,
    [GRAPE_FRUIT]: default_grape_config
};

