/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Col } from "reactstrap";
import { getBackendVersion, restoreTenant } from "../../../actions/Auth/actions";
import useConfig from "../../../actions/Tenants/config/configHook";
import ED from "../../../img/logo-ed.png";
import FooterEyePlatformStripes from "./FooterEyePlatformStripes";

export default function Footer() {
    const user = useSelector((state) => state.auth.user);
    const location = useLocation();
    const testing = user.email && user.email.endsWith("experiencedata.nl") && user.tenant.tenant_id !== "a6aff297-ac1b-4e81-88a6-420591d31f31";

    const testingBlockStyle = css`
        ${testing ? "bottom:3.8rem;" : "bottom:0;"}
    `;
    const footerBlockHeight = css`
        ${testing ? "height: 15rem;" : "height: 12.5rem;"}
    `;

    const LayerIndex = location.pathname === "/" || location.pathname === "/layer";


    return (

        <div className="">
            <div css={footerBlockHeight} className="footer text-center text-muted py-3 d-flex align-items-center">
                {(LayerIndex) && <FooterEyePlatformStripes top={testing ? "0" : "3.8rem"}/>}
                {!LayerIndex && <div css={testingBlockStyle} className="footer-copyright d-flex">
                    <Col lg="2">
                    </Col>
                    <Col xs={8} md={8}>
                        <small>
                            Powered by
                            <img src={ED} alt="Logo Experience Data" className="ms-2 d-inline-block" />
                            <a className="text-dark ms-1 text-decoration-none" target="_blank" href="https://www.experiencedata.nl/" rel="noreferrer">Experience Data</a>
                        </small>
                    </Col>
                    <Col xs={4} lg="2">
                        <VersionTag />
                    </Col>
                </div>}
            </div>
            {testing && <TestBanner />}
        </div>
    );
}

function VersionTag() {
    const user = useSelector((state) => state.auth.user);

    const backend_version = useSelector((state) => state.auth.backend_version);
    const frontend_version = process.env?.REACT_APP_VERSION;
    const dispatch = useDispatch();
    const timer = useRef();
    const version_not_equal = frontend_version && backend_version && frontend_version !== backend_version;
    const isED = user.tenant.tenant_id === "a6aff297-ac1b-4e81-88a6-420591d31f31";

    useEffect(() => {
        dispatch(getBackendVersion());
        timer.current = setInterval(() => dispatch(getBackendVersion()), 10000);
        return () => clearInterval(timer.current);
    }, []);


    if (!frontend_version) {
        return <small>Version <b>dev</b></small>;
    }

    if (isED) {
        return <small title={`frontend: ${frontend_version} - backend: ${backend_version}`}>
            Version <b><a href={`https://github.com/experiencedata/avos_server/commit/${frontend_version}`} target="_blank" rel="noreferrer">{frontend_version.slice(0, 7)}</a></b>
            {version_not_equal && <span> / <a className="fw-bold" href={`https://github.com/experiencedata/avos_server/commit/${backend_version}`} target="_blank" rel="noreferrer">{backend_version.slice(0, 7)}</a></span>}
        </small>;
    }

    return <small title={`frontend: ${frontend_version} / backend: ${backend_version}`}>
        Version <b>{frontend_version.slice(0, 7)}</b>{version_not_equal && <span className="fw-bold">-{backend_version.slice(0, 7)}</span>}
    </small>;
}


function TestBanner() {
    const params = useParams();
    const config = useConfig();
    const user = useSelector((state) => state.auth.user);
    const layer = useSelector((state) => state.layers.current);
    const check = useSelector((state) => state.checks.current);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const restoreTenantAndNavigate = () => {
        navigate(`/tenants`);
        dispatch(restoreTenant());
    };

    const urls = [
        {
            url: `/tenants/${user.tenant.tenant_id}`,
            text: `Edit ${user.tenant.tenant_name} config`
        }
    ];
    if (params.layer_id && layer?.type) {
        const layer_config = config.configs.find((c) => c.value === layer.type && c.type === "layer_type");
        if (layer_config) {
            urls.push({
                url: `/tenants/${user.tenant.tenant_id}/config/${layer_config.id}`,
                text: `Edit ${layer_config.config.text} config`
            });
        }
    }
    if (params.test_id && check?.location) {
        const check_config = config.configs.find((c) => c.value === check.location && c.type === "check_location");
        if (check_config) {
            urls.push({
                url: `/tenants/${user.tenant.tenant_id}/config/${check_config.id}`,
                text: `Edit ${check_config.config.text} config`
            });
        }
    }

    return (
        <Alert color="danger" className="mb-0">

            <div className="py-3">
                <div className="text-center font-weight-bold pb-2 ">You are testing the frontend as <b>{user.tenant.tenant_name}</b>.</div>
                <div className="text-center">
                    {urls.map((i, index) => <Button key={index} color="secondary" size="sm" className="mx-1" onClick={() => navigate(i.url)} >{i.text}</Button>)}
                    <Button color="secondary" size="sm" className="mx-1" onClick={() => restoreTenantAndNavigate()} >Back to tenants</Button></div>
            </div>
        </Alert>
    );
}
