/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Col,
    Row,
    Table,
    Button
} from "reactstrap";
import { getSoftwareStatus, createDeviceEvent } from "../../../../actions/Devices/actions";
import { Loading } from "../../../Helper/Loading";
import Pagination from "../../../Pagination";
import DeviceEventStatusBadge from "../../../Devices/DeviceEventStatusBadge";

export function DeviceUpdateOptionTable() {
    const [limit, setLimit] = useState(25);
    const [update, setUpdate] = useState(null);
    const isLoading = useSelector((state) => state.devices.isLoading);
    const softwareStatus = useSelector((state) => state.devices.softwareStatus);
    const [event, setEvent] = useState({});
    const device = useSelector((state) => state.devices.current);
    const isDataUnavailable = isLoading || !softwareStatus || !device.UID;
    const dispatch = useDispatch();

    useEffect(() => {
        const offset = 0;
        dispatch(getSoftwareStatus({ limit, offset }));
    }, []);


    const onPageChanged = (data) => {
        const { currentPage, pageLimit } = data;
        const offset = Math.max((currentPage - 1) * pageLimit, 0);
        dispatch(getSoftwareStatus({ limit, offset }));
    };

    const updateVersion = (data) => {
        dispatch(createDeviceEvent({
            device_id: device.UID,
            command: "update_mcu",
            data: {
                version: data.version,
                url_binary: data.blobname
            }
        })).then((response) => {
            if (!response.error) {
                setEvent(response.payload.data);
            }
        });
    };

    if (isLoading) {
        return <Loading/>;
    }

    const redirectToNewURL = (item) => {
        window.open(`${item.github_url}/${item.version}`, "_blank");
    };
    const datadump = JSON.stringify(event || {}, null, "\t");

    return (
        <div>
            <Row>
                <Col>
                    { (update)
                        && <div>
                            <Table hover responsive striped>
                                <thead>
                                    <tr css={css`font-size:14px;`}>
                                        <th><span id="type">Type</span></th>
                                        <th><span id="version">Version</span></th>
                                        <th><span id="blob_name">Blob name</span></th>
                                        <th><span id="description">Description</span></th>
                                    </tr>
                                </thead>
                                <tbody css={css`font-size:14px;`}>
                                    <tr>
                                        <td><p>{update.type}</p></td>
                                        <td><p>{update.version}</p></td>
                                        <td><p>{update.blobname}</p></td>
                                        <td><p>{update.description}</p></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    }
                    <Col>
                        {event.id && <div >
                            <div className="d-flex justify-content-between ">
                                <h5>Event: {event.id}</h5>
                                <div>
                                    <DeviceEventStatusBadge event={event} />
                                </div>
                            </div>
                            <div className="p-3" css={css`background: #f9f9f9;`}>
                                <pre>
                                    {datadump}
                                </pre>
                            </div>
                        </div>}
                    </Col>
                    <Button disabled={!update} color="primary" onClick={() => updateVersion(update)}> Update </Button>
                    {isLoading && <Loading />}
                    { (!isLoading && softwareStatus)
                        && <div css={css`min-height: 30vh;`}>
                            <Table hover responsive striped>
                                <thead>
                                    <tr css={css`font-size:14px;`}>
                                        <th><span id="type">Type</span></th>
                                        <th><span id="version">Version</span></th>
                                        <th><span id="blob_name">Blob name</span></th>
                                        <th><span id="description">Description</span></th>
                                    </tr>
                                </thead>
                                <tbody css={css`font-size:14px;`}>
                                    {softwareStatus.results.map((item, index) => {
                                        return <tr key={index}>
                                            <td css={css`cursor: pointer;`} onClick={() => setUpdate(item)}><p>{item.type}</p></td>
                                            <td css={css`cursor: pointer; a:hover{text-decoration: underline !important; color: blue  !important;}`} onClick={() => redirectToNewURL(item)}>
                                                <a>{item.version}</a></td>
                                            <td css={css`cursor: pointer;`} onClick={() => setUpdate(item)}><p>{item.blobname}</p></td>
                                            <td css={css`cursor: pointer;`} onClick={() => setUpdate(item)}><p>{item.description}</p></td>
                                        </tr>;
                                    })}
                                </tbody>
                            </Table>
                        </div>
                    }
                </Col>
            </Row>
            <Row className="align-items-center pt-1">
                <Col md="4" className="py-2">
                    <Pagination
                        totalRecords={softwareStatus?.count || 0}
                        pageLimit={limit}
                        pageNeighbours={1}
                        onPageChanged={onPageChanged}
                    />
                </Col>
                <Col md="4" className="text-center py-2">
                    <small>
                        {isDataUnavailable ? `0` : softwareStatus?.results?.length} of {softwareStatus.count} results
                    </small>
                </Col>
                <Col md="4" className="text-end text-md-right col-sm py-2">
                    <div className="btn-group-sm" role="group" aria-label="limit">
                        {[12, 25, 50, 100].map((i) => (<button key={i} type="button" onClick={() => setLimit(i)} className={`ms-2 btn btn-outline-dark ${i === limit ? "active" : ""}`}>{i}</button>))}
                    </div>
                </Col>
            </Row>
        </div>
    );
}
