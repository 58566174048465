/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from "react";
import { Input, InputGroup, InputGroupText } from "reactstrap";

const handleFocus = (event) => event.target.select();

const isValidNumber = (number) => number !== "" && number !== null && number !== false && (number < 0 || number > 0 || parseFloat(number) === 0);

export interface NumericInputFieldProps {
    field: string;
    invalid?: boolean;
    label?: string;
    unity: string | boolean;
    db_value: string | number;
    setValue: (field: string, value: string | number) => void;
    min: boolean | number | string;
    max?: boolean | number | string;
    readOnly?: boolean;
    disabled?: boolean;
    appendendStyle?: string;
}


// TODO:: this component has some flaws, due to localstate it is impossible to update the value from outside the component
// TODO: this component together with the backend cannot remove values
export default function NumericInputField({ field, db_value, unity = false, min = 0, max = false, setValue, invalid = false, label, readOnly = false, disabled = false, appendendStyle = "" }:NumericInputFieldProps) {
    const [inputvalue, setInputValue] = useState(db_value);
    const [error, setErrorMessage] = useState<string>();
    const effectTimer = useRef<any>(null);

    // wait 300ms before updating the local state from db value
    useEffect(() => {
        clearTimeout(effectTimer.current);

        effectTimer.current = setTimeout(() => {
            setInputValue(db_value);
        }, 300);

        return () => clearTimeout(effectTimer.current);
    }, [db_value]);

    const _submit = () => {
        if (error) {
            return;
        }
        // clear the effectTimer to prevent a funny user experience
        clearTimeout(effectTimer.current);

        setValue(field, inputvalue);
    };

    const _onChange = (e) => {
        // clear the effectTimer to prevent a funny user experience
        clearTimeout(effectTimer.current);

        const { value } = e.target;

        if (isValidNumber(min) && parseFloat(value) < parseFloat(min as string)) {
            setErrorMessage(`${label || field} must be higher then ${min}`);
        } else if (isValidNumber(max) && parseFloat(value) > parseFloat(max as string)) {
            setErrorMessage(`${label || field} must be lower then ${max}`);
        } else if (isValidNumber(value) || value === "") {
            setErrorMessage(undefined);
            setValue(field, value);
        } else {
            setErrorMessage("Please fill in a valid number.");
        }
        setInputValue(value);
    };


    return (<div>
        <InputGroup>
            <Input
                onFocus={handleFocus}
                onWheel={(e: any) => e.target.blur()}
                invalid={Boolean(error) || invalid}
                readOnly={readOnly}
                disabled={disabled} type="number"
                onChange={(e) => _onChange(e)}
                onBlur={() => _submit()} className="text-start" value={inputvalue || ""} />
            {unity && <InputGroupText css={appendendStyle}>{unity}</InputGroupText>}
        </InputGroup>
        <div className="position-relative">
            {error && <small className="position-absolute text-danger">{error || "\u00A0"}</small>}
        </div>
    </div>

    );
}
