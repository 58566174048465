/* eslint-disable react/no-unused-prop-types */
import { Html5QrcodeScanner, Html5QrcodeScannerState } from "html5-qrcode";
import { useEffect } from "react";
import { BarcodeFilter } from "../../actions/Tenants/config/constantsTyped";
import { createConfig } from "./QRCodeScanner";

const qrcodeRegionId = "html5qr-code-full-region";

interface Html5QrcodePluginProps {
    fps: number;
    verbose?: boolean;
    qrbox: number;
    aspectRatio?: number;
    disableFlip: boolean;
    qrCodeSuccessCallback: (onNewScanResult: string, decodedResult: any) => void;
    qrCodeErrorCallback?: () => void;
    barcode_filter?: BarcodeFilter;
}

export const Html5QrcodePlugin = ({
    fps = 10,
    verbose: _verbose = false,
    aspectRatio,
    qrbox = 250,
    disableFlip = false,
    qrCodeSuccessCallback = () => null,
    qrCodeErrorCallback = () => null,
    barcode_filter
}: Html5QrcodePluginProps) => {

    useEffect(() => {
        // when component mounts
        const { filter, formatsToSupport } = typeof barcode_filter === "object" ? barcode_filter : { filter: barcode_filter, formatsToSupport: undefined };
        const config = createConfig({ fps, disableFlip, qrbox, aspectRatio, formatsToSupport });
        const verbose = _verbose === true;

        const html5QrcodeScanner = new Html5QrcodeScanner(qrcodeRegionId, config, verbose);
        html5QrcodeScanner.render((decodedText, decodedResult) => {
            if (navigator.userAgent.toLocaleLowerCase().match(/android/i) && decodedText.startsWith("]C1")) {
                decodedText = decodedText.substring(3);
            }
            const filteredValue = filter?.(decodedText);
            if (filteredValue) {
                // pause the scanner after a successful scan
                if (html5QrcodeScanner.getState() === Html5QrcodeScannerState.SCANNING) { html5QrcodeScanner.pause(true); }
                // call the callback function
                return qrCodeSuccessCallback(filteredValue, decodedResult);
            }
            return qrCodeSuccessCallback(decodedText, decodedResult);
        }, qrCodeErrorCallback);

        // cleanup function when component will unmount
        return () => {
            html5QrcodeScanner.clear().catch((error) => {
                console.error("Failed to clear html5QrcodeScanner. ", error); // eslint-disable-line no-console
            });
        };
    }, []);

    return (
        <div id={qrcodeRegionId} />
    );
};


interface QRCodeScannerProps {
    onScan: (decodedText: string) => void;
    barcode_filter?: BarcodeFilter;
}

function QRCodeScanner({ onScan, barcode_filter }: QRCodeScannerProps) {

    const onNewScanResult = (decodedText, decodedResult) => {
        // handle decoded results here
        console.log("Found code", decodedText, decodedResult); // eslint-disable-line no-console
        onScan(decodedText);
    };

    return (
        <Html5QrcodePlugin
            fps={10}
            qrbox={250}
            disableFlip={false}
            qrCodeSuccessCallback={onNewScanResult}
            barcode_filter={barcode_filter}
        />
    );
}

export default QRCodeScanner;
