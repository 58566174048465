
/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePackingListImportData } from "../../../actions/PackingList/actions";
import useConfig from "../../../actions/Tenants/config/configHook";
import { ChildItemType, MetaItemType, PackingListPreviewType } from "./types";

export default function useEnrichPreviewHook() {
    const user = useSelector((state: any) => state.auth.user);
    const { tenant_id } = user.tenant;
    const preview: PackingListPreviewType = useSelector((state: any) => state.packingLists.raw_json);
    const import_data = useSelector((state: any) => state.packingLists.import_data);
    const dispatch = useDispatch();
    const allOptions = useSelector((state: any) => state?.tenants?.options_by_field);
    const tenantOptions = allOptions[tenant_id];
    const config = useConfig();

    const makeComparable = (value: string) => {
        if (typeof value !== "string") {
            value = String(value);
        }
        // value to lower case, remove all special chars, remove all spaces
        return value.toLowerCase().replace(/[^a-zA-Z0-9]/g, "").replace(/\s/g, "");
    };

    // const addCrucialMeta = (meta: MetaItemType[]) => {
    //     const { supplier, fruit_type, country } = query;
    //     return meta.map((i) => {
    //         if (i.key === "supplier" && supplier) {
    //             return { ...i, value: supplier };
    //         } if (i.key === "fruit_type" && fruit_type) {
    //             return { ...i, value: fruit_type };
    //         } if (i.key === "country" && country) {
    //             return { ...i, value: country };
    //         }
    //     });
    // };

    const enrichMeta = (meta: MetaItemType) => {
        const { key, value } = meta;
        const options = tenantOptions[key];


        if (!value || value === "nan") {
            return { key, value, label: "-", isManaged: false, isValid: true };
        }

        // * If there are options, then it is a managed field
        if (!options) {
            return { key, value, label: value, isManaged: false, isValid: true };
        }

        // * try to find the option for this label or value
        const comparableValue = makeComparable(value);
        const option = options.find((i) => makeComparable(i.value) === comparableValue || makeComparable(i.label) === comparableValue);

        if (!option) {
            // * This field is managed, but the value is not in the options
            return { key, value, label: value, isManaged: true, isValid: false };
        }
        // * if we can match an option, then it is valid and we override the value to the managed value
        return { key, value: option.value, label: option.label, isManaged: true, isValid: true };

    };

    const filterLayer = (item: ChildItemType) => ({
        ...item,
        layer_type: config.get_raw_layer_config(item.type),
        meta: item.meta.map(enrichMeta),
        children: item.children.map(filterLayer)
    }
    );

    useEffect(() => {
        if (!preview) {
            dispatch(updatePackingListImportData(false));
            return;
        }
        dispatch(updatePackingListImportData(filterLayer(preview)));

    }, [preview, tenantOptions]);


    return import_data;

}
