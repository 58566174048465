import { SendReportModal } from "./SendReportToSupplierModal";
import { blobToBase64 } from "../../../../utils/base64";


export interface SendReportRequestBody {
    layer_id: string;
    report_base64: string;
    report_type: string;
    report_file_type: string;
}

export interface SendReportButtonProps {
    blob: Blob | undefined;
    layerId: string;
    reportType: string;
    onSendReport: (RequestBody: SendReportRequestBody) => void;
}


export const SendReportButton = ({ layerId, blob, reportType, onSendReport }:SendReportButtonProps) => {
    const onSend = async () => {
        const base64Data = await blobToBase64(blob);
        await onSendReport({
            layer_id: layerId,
            report_base64: base64Data,
            report_type: reportType,
            report_file_type: "pdf"
        });
    };

    if (!blob) return <></>;
    const pdfSizeInMB = blob ? blob.size / 1024 / 1024 : 0;
    return <SendReportModal onSend={onSend} reportType={reportType} pdfSizeInMB={pdfSizeInMB} />;
};
