import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import DatePickerInput from "./DatePickerInput";

export default function DatePicker(props) {
    const [state, setstate] = useState("");

    useEffect(() => {
        setstate(toFormatted(props.value));
    }, [props.value]);

    const toFormatted = (value) => {
        if (value) {
            const dj = dayjs(value);
            if (dj.isValid()) {
                return dj.format(props.format);
            }
        }
        return null;
    };

    const onChange = (value) => {
        setstate(value);
        const formatted = toFormatted(value);
        props.onChange(formatted);
    };

    return <DatePickerInput onChange={onChange} value={state || ""} />;
}

DatePicker.defaultProps = {
    value: null,
    format: "YYYY-MM-DD",
};

DatePicker.propTypes = {
    value: PropTypes.any,
    format: PropTypes.string,
    onChange: PropTypes.func,
};
