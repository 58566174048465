import { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Alert,
    Button,
    FormGroup, FormText,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
import { importPackingList, listPackingList, previewPackingList, resetPreviewPackingList, updatePackingListStep } from "../../../actions/PackingList/actions";
import useConfig from "../../../actions/Tenants/config/configHook";
import MetaForm, { metaFormIsValid } from "../../Forms/MetaForm";
import WrappedPreviewPackingList from "./PreviewPackingList";
import { toast } from "../../../utils/toast";

import useEnrichPreviewHook from "./useEnrichPreviewHook";

import { FormField } from "../../../actions/Tenants/config/constantsTyped";

interface PackingListUploadModalProps {
    isOpen: boolean;
    toggle: () => void;
}
export const STEPS = {
    PICK_FILE_AND_REVIEW_CONTEXT: 0,
    REVIEW_CHILDS_AND_IMPORT: 1,
};

export default function PackingListUploadModal({ isOpen, toggle }: PackingListUploadModalProps) {
    const [selectedFile, setSelectedFile] = useState<File | undefined>();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const existing_layers = useSelector((state: any) => state.packingLists.existing_layers);
    const isLoading = useSelector((state: any) => state.packingLists.isLoading);
    const isUpating = useSelector((state: any) => state.packingLists.isUpdating);
    const step = useSelector((state: any) => state.packingLists.step);
    const list_id = useSelector((state: any) => state.packingLists.list_id);
    const dispatch = useDispatch();
    const config = useConfig();
    const [query, setQuery] = useState({ supplier: "", country: "", fruit_type: "", packing_list_template: "default" });
    const import_data = useEnrichPreviewHook();

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(resetPreviewPackingList() as any);
        if (event.target.files && event.target.files.length > 0) {

            const file = event.target.files[0];
            // Validate file type
            const allowedFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel", "application/vnd.oasis.opendocument.spreadsheet", "application/vnd.ms-excel.sheet.macroEnabled.12", "text/csv"];

            if (!allowedFileTypes.includes(file.type)) {
                toast.error("Invalid file type. Please select a CSV, XLSX, or XLS file.");
                return;
            }

            setSelectedFile(file);
            setIsFilePicked(true);
        }
    };

    const cancel = () => {
        dispatch(listPackingList({ limit: 25, offset: 0 }) as any);
        dispatch(resetPreviewPackingList() as any);
        setSelectedFile(undefined);
        setIsFilePicked(false);
        toggle();
    };

    const form: FormField[] = [
        {
            label: "Country",
            name: "country",
            type: "select-managed",
            mandatory: true,
        },
        {
            label: "Supplier",
            name: "supplier",
            type: "select-managed",
            onchange_callback: ({ setValue, field, value }) => {
                setValue(field, value);
                setValue("template", "default");
            },
            mandatory: true,
        },
        {
            label: "Template",
            name: "packing_list_template",
            type: "select-managed",
            hide_when_no_options: true
        },
        {
            label: "Fruit",
            name: "fruit_type",
            type: "select-managed",
        },
    ];

    const handlePreview = () => {
        if (!selectedFile) {
            return;
        }

        if (!metaFormIsValid(form, query)) {
            return;
        }
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("template", query.packing_list_template);
        formData.append("country", query.country);
        formData.append("supplier", query.supplier);
        formData.append("fruit_type", query.fruit_type);

        dispatch(previewPackingList(formData) as any);
    };

    const handleImport = () => {

        dispatch(importPackingList(list_id, import_data) as any).then((response) => {
            if (!response.error) {
                dispatch(listPackingList({ limit: 25, offset: 0 }) as any);
                cancel();
            }
        });
    };

    const importableState = import_data && isFilePicked && step === STEPS.REVIEW_CHILDS_AND_IMPORT;

    return (
        <Modal fullscreen={true} isOpen={isOpen} toggle={() => cancel()} >
            <ModalHeader toggle={() => cancel()} >Upload packing list</ModalHeader>
            <ModalBody>
                {existing_layers !== false && <Alert color="info">Some pallets / orders are already imported. You can not import this file again.</Alert>}
                {step === STEPS.PICK_FILE_AND_REVIEW_CONTEXT && <div>
                    { !import_data && <MetaForm meta={form} setValue={(field, value) => setQuery((query) => ({ ...query, [field]: value }))} object={query} config={config} /> }
                    <FormGroup>
                        <Label for="exampleFile">Excel sheet</Label>
                        <Input
                            id="exampleFile"
                            name="file"
                            type="file"
                            accept=".csv,.xlsx,.xlsm,.xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.oasis.opendocument.spreadsheet, application/vnd.ms-excel.sheet.macroEnabled.12"
                            onChange={changeHandler}
                        />

                        {isFilePicked ? (
                            <FormText >
                                <div className="pt-2">
                                    <div>Filename: {selectedFile?.name}</div>
                                    <div>Filetype: {selectedFile?.type}</div>
                                    <div>Size in bytes: {selectedFile?.size}</div>
                                </div>
                            </FormText>
                        ) : (
                            <FormText>
                            Upload file
                            </FormText>
                        )}
                    </FormGroup>
                </div> }
                {(import_data || isUpating) && <WrappedPreviewPackingList />}
            </ModalBody>
            <ModalFooter>
                <Button color="light" onClick={() => cancel()}>Cancel</Button>
                {step === STEPS.PICK_FILE_AND_REVIEW_CONTEXT && !import_data && <Button color="primary" disabled={!isFilePicked || isLoading || isUpating} onClick={() => handlePreview()}>Preview</Button>}
                {step === STEPS.PICK_FILE_AND_REVIEW_CONTEXT && import_data && <Button color="primary" disabled={!isFilePicked || isLoading || isUpating || !import_data} onClick={() => dispatch(updatePackingListStep(STEPS.REVIEW_CHILDS_AND_IMPORT))}>Next</Button>}
                {step === STEPS.REVIEW_CHILDS_AND_IMPORT && <Button color="light" onClick={() => dispatch(updatePackingListStep(STEPS.PICK_FILE_AND_REVIEW_CONTEXT))}>Back</Button>}
                {importableState && <Button color="warning" disabled={!isFilePicked || isLoading || isUpating || existing_layers !== false} onClick={() => handleImport()}>Import</Button>}


            </ModalFooter>
        </Modal>


    );
}


