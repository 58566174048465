/** @jsxImportSource @emotion/react */
import { faSend } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader, Row
} from "reactstrap";


const MAX_PDF_SIZE_IN_MB = 8;

interface SendReportModalProps {
    onSend: () => void;
    reportType: string;
    pdfSizeInMB: number;
    checkPDFSize?: boolean;
}

export const SendReportModal = ({ onSend, reportType, pdfSizeInMB, checkPDFSize }: SendReportModalProps) => {

    const [isOpen, setIsOpen] = useState(false);

    const onSendButtonClick = () => {
        setIsOpen(false);
        onSend();
    };
    const exceedsThePdfSizeLimit = pdfSizeInMB > MAX_PDF_SIZE_IN_MB;
    const isPdfEmpty = pdfSizeInMB === 0;
    const sendReportButtonDisabled = checkPDFSize && (isPdfEmpty || exceedsThePdfSizeLimit);
    return <>
        <div>{
            checkPDFSize && exceedsThePdfSizeLimit ? <span className="text-warning p-2">The report size exceeds the limit of 8MB</span> : <></>
        }</div>
        <div className="pe-4">
            <Button color="primary" outline className="text-nowrap" disabled={sendReportButtonDisabled} size="sm" onClick={() => setIsOpen(true)}> Send {reportType} report <FontAwesomeIcon icon={faSend} /></Button>
        </div>
        <Modal key={1} isOpen={isOpen} size="md">
            <ModalHeader >Send {reportType} report to the supplier</ModalHeader>
            <ModalBody>
                <Row><p>Are you sure you want to send the {reportType} report to the supplier?</p></Row>
            </ModalBody>
            <ModalFooter>
                <Button color="light" onClick={() => setIsOpen(false)}>Cancel</Button>
                <Button color="success" onClick={onSendButtonClick}>Send <FontAwesomeIcon icon={faSend} /></Button>
            </ModalFooter>
        </Modal>
    </>;
};
