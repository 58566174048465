import { CREATE, LIST, UPDATE } from "./actionsTypes";


const packingListsState = {
    items: false,
    isLoading: false,
    isUpdating: false,
    current: {}, // * not in use yet
    raw_json: false,
    existing_layers: false,
    list_id: false,
    step: 0,
    filter: { search: "", limit: 50, ordering: "" },
};
export default (state = packingListsState, action) => {
    switch (action.type) {
    case LIST.LOADING:
        return { ...state, isLoading: true };
    case LIST.SUCCESS:
        return { ...state, isLoading: false, items: action.payload.data };

    case CREATE.LOADING:
        return { ...state, isUpdating: true };
    case CREATE.RESET:
        return { ...state, raw_json: false, existing_layers: false, list_id: false, step: 0, import_data: false };
    case CREATE.SUCCESS:
        return { ...state, isUpdating: false, raw_json: action.payload.data.preview, existing_layers: action.payload.data.existing_layers, list_id: action.payload.data.list_id };
    case CREATE.ERROR:
        return { ...state, isUpdating: false, raw_json: false, existing_layers: false, list_id: false, import_data: false };

    case UPDATE.STEP:
        return { ...state, step: action.payload };

    case UPDATE.IMPORT_DATA:
        return { ...state, import_data: action.payload };


    default:
        return state;
    }
};
