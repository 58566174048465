import { LIST } from "./actionsTypes";

export interface MailboxState {
    isLoading: boolean;
    items: any;
    filter: {
        offset: number;
        limit: number;
    };
}

const initialState = {
    isLoading: false,
    items: {
        results: [],
        count: 0,
    },
    filter: { offset: 0, limit: 25 },
};

export default (state = initialState, action) => {
    switch (action.type) {
    case LIST.LOADING:
        return { ...state, isLoading: true };
    case LIST.ERROR:
        return { ...state, isLoading: false };
    case LIST.SUCCESS:
        return { ...state, isLoading: false, items: action.payload.data };
    case LIST.FILTER:
        return { ...state, filter: action.payload };
    default:
        return state;
    }
};
