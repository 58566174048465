import {
    CREATE,
    CREATE_CONFIG,
    CREATE_FIELD_OPTION,
    CREATE_MASTER_DEFECT,
    DELETE,
    DELETE_FIELD_OPTION,
    DELETE_MASTER_DEFECT,
    GET,
    GET_CONFIG,
    LIST,
    LIST_ALL_CONFIGS,
    LIST_ALL_CONFIG_REVISIONS,
    LIST_ALL_FIELD_OPTIONS,
    LIST_ALL_MASTER_DEFECTS,
    LIST_FIELD_OPTIONS,
    LIST_MASTER_DEFECTS,
    UPDATE,
    UPDATE_CONFIG,
    UPDATE_CONFIG_STATE,
    UPDATE_FIELD_OPTION,
    UPDATE_MASTER_DEFECT,
} from "./actionsTypes";

const endpoint = "tenants/";

export function listTenants() {
    return {
        types: [LIST.LOADING, LIST.SUCCESS, LIST.ERROR],
        payload: {
            request: {
                method: "GET",
                url: endpoint,
            },
        },
    };
}

export function getTenant(tenant_id) {
    return {
        types: [GET.LOADING, GET.SUCCESS, GET.ERROR],
        payload: {
            request: {
                method: "GET",
                url: `${endpoint + tenant_id}/`,
            },
        },
    };
}
export function createTenant(data) {
    return {
        types: [CREATE.LOADING, CREATE.SUCCESS, CREATE.ERROR],
        payload: {
            request: {
                method: "POST",
                url: endpoint,
                data,
            },
        },
    };
}

export function deleteTenant(tenant_id) {
    return {
        types: [DELETE.LOADING, DELETE.SUCCESS, DELETE.ERROR],
        payload: {
            request: {
                method: "DELETE",
                tenant_id,
                url: `${endpoint + tenant_id}/`,
            },
        },
    };
}

export function updateTenant(data) {
    const { tenant_id } = data;
    return {
        types: [UPDATE.LOADING, UPDATE.SUCCESS, UPDATE.ERROR],
        payload: {
            request: {
                method: "PUT",
                tenant_id,
                url: `${endpoint + tenant_id}/`,
                data,
            },
        },
    };
}

export function updateTenantState(data) {
    return {
        type: GET.SUCCESS,
        payload: { data },
    };
}

export function getTenantFieldOptions(tenant_id, fieldName) {
    return {
        types: [
            LIST_FIELD_OPTIONS.LOADING,
            LIST_FIELD_OPTIONS.SUCCESS,
            LIST_FIELD_OPTIONS.ERROR,
        ],
        payload: {
            request: {
                method: "GET",
                url: `${endpoint}${tenant_id}/form-field-options/${fieldName}/`,
            }
        }
    };
}

export function getAllTenantFieldOptions(tenant_id) {
    return {
        types: [
            LIST_ALL_FIELD_OPTIONS.LOADING,
            LIST_ALL_FIELD_OPTIONS.SUCCESS,
            LIST_ALL_FIELD_OPTIONS.ERROR,
        ],
        payload: {
            request: {
                method: "GET",
                tenant_id,
                url: `${endpoint}${tenant_id}/form-field-options/all`,
            }
        }
    };
}

export function createTenantFieldOption(tenant_id, fieldName, data) {
    return {
        types: [
            CREATE_FIELD_OPTION.LOADING,
            CREATE_FIELD_OPTION.SUCCESS,
            CREATE_FIELD_OPTION.ERROR,
        ],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint}${tenant_id}/form-field-options/${fieldName}/`,
                data,
            }
        }
    };
}

export function deleteTenantFieldOption(tenant_id, fieldName, option_id) {
    return {
        types: [
            DELETE_FIELD_OPTION.LOADING,
            DELETE_FIELD_OPTION.SUCCESS,
            DELETE_FIELD_OPTION.ERROR,
        ],
        payload: {
            request: {
                method: "DELETE",
                url: `${endpoint}${tenant_id}/form-field-options/${fieldName}/${option_id}`,
            }
        }
    };
}

export function deleteAllTenantFieldOption(tenant_id, fieldName) {
    return {
        types: [
            DELETE_FIELD_OPTION.LOADING,
            DELETE_FIELD_OPTION.SUCCESS,
            DELETE_FIELD_OPTION.ERROR,
        ],
        payload: {
            request: {
                method: "DELETE",
                url: `${endpoint}${tenant_id}/form-field-options/${fieldName}/all`,
            }
        }
    };
}

export function updateTenantFieldOption(tenant_id, fieldName, data) {
    return {
        types: [
            UPDATE_FIELD_OPTION.LOADING,
            UPDATE_FIELD_OPTION.SUCCESS,
            UPDATE_FIELD_OPTION.ERROR,
        ],
        payload: {
            request: {
                method: "PUT",
                url: `${endpoint}${tenant_id}/form-field-options/${fieldName}/${data.id}`,
                data,
            }
        }
    };
}

export function importTenantFieldOption(tenant_id, fieldName, data) {
    return {
        types: [
            UPDATE_FIELD_OPTION.LOADING,
            UPDATE_FIELD_OPTION.SUCCESS,
            UPDATE_FIELD_OPTION.ERROR,
        ],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint}${tenant_id}/form-field-options/${fieldName}/import`,
                data,
            }
        }
    };
}

export function resetAllTenantFieldOption(tenant_id, data) {
    return {
        types: [
            UPDATE_FIELD_OPTION.LOADING,
            LIST_ALL_FIELD_OPTIONS.SUCCESS,
            UPDATE_FIELD_OPTION.ERROR,
        ],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint}${tenant_id}/form-field-options/reset-all`,
                data,
            }
        }
    };
}


// * config actions
export function getTenantConfig(tenant_id, config_id) {
    return {
        types: [
            GET_CONFIG.LOADING,
            GET_CONFIG.SUCCESS,
            GET_CONFIG.ERROR,
        ],
        payload: {
            request: {
                method: "GET",
                url: `${endpoint}${tenant_id}/configs/${config_id}`,
            }
        }
    };
}


export function getAllActiveTenantConfigs(tenant_id) {
    return {
        types: [
            LIST_ALL_CONFIGS.LOADING,
            LIST_ALL_CONFIGS.SUCCESS,
            LIST_ALL_CONFIGS.ERROR,
        ],
        payload: {
            request: {
                method: "GET",
                tenant_id,
                url: `${endpoint}${tenant_id}/configs/active`,
            }
        }
    };
}

export function getTenantConfigRevisions(tenant_id, config_id) {
    return {
        types: [
            LIST_ALL_CONFIG_REVISIONS.LOADING,
            LIST_ALL_CONFIG_REVISIONS.SUCCESS,
            LIST_ALL_CONFIG_REVISIONS.ERROR,
        ],
        payload: {
            request: {
                method: "GET",
                tenant_id,
                url: `${endpoint}${tenant_id}/configs/${config_id}/revisions`,
            }
        }
    };
}

export function createTenantConfig(tenant_id, data) {
    return {
        types: [
            CREATE_CONFIG.LOADING,
            CREATE_CONFIG.SUCCESS,
            CREATE_CONFIG.ERROR,
        ],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint}${tenant_id}/configs`,
                data,
            }
        }
    };
}

export function patchTenantConfig(tenant_id, config_id, field, value) {
    return {
        types: [
            UPDATE_CONFIG.LOADING,
            UPDATE_CONFIG.SUCCESS,
            UPDATE_CONFIG.ERROR,
        ],
        payload: {
            request: {
                method: "PATCH",
                tenant_id,
                url: `${endpoint}${tenant_id}/configs/${config_id}`,
                data: { field, value },
            }
        }
    };
}


export function updateTenantConfigState(data) {
    return {
        type: UPDATE_CONFIG_STATE.SUCCESS,
        payload: { data },
    };
}


export function resetAllTenantConfig(tenant_id, data) {
    return {
        types: [
            LIST_ALL_CONFIGS.LOADING,
            LIST_ALL_CONFIGS.SUCCESS,
            LIST_ALL_CONFIGS.ERROR,
        ],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint}${tenant_id}/configs/reset-all`,
                data,
                tenant_id
            }
        }
    };
}

// * Tenant defects (master defects)
export function getTenantDefects(tenant_id, fruit_type) {
    return {
        types: [
            LIST_MASTER_DEFECTS.LOADING,
            LIST_MASTER_DEFECTS.SUCCESS,
            LIST_MASTER_DEFECTS.ERROR,
        ],
        payload: {
            request: {
                method: "GET",
                url: `${endpoint}${tenant_id}/defects/${fruit_type}/`,
            }
        }
    };
}

export function getAllTenantDefects(tenant_id) {
    return {
        types: [
            LIST_ALL_MASTER_DEFECTS.LOADING,
            LIST_ALL_MASTER_DEFECTS.SUCCESS,
            LIST_ALL_MASTER_DEFECTS.ERROR,
        ],
        payload: {
            request: {
                method: "GET",
                tenant_id,
                url: `${endpoint}${tenant_id}/defects/all`,
            }
        }
    };
}

export function createTenantDefect(tenant_id, fruit_type, data) {
    return {
        types: [
            CREATE_MASTER_DEFECT.LOADING,
            CREATE_MASTER_DEFECT.SUCCESS,
            CREATE_MASTER_DEFECT.ERROR,
        ],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint}${tenant_id}/defects/${fruit_type}/`,
                data,
            }
        }
    };
}

export function deleteTenantDefect(tenant_id, fruit_type, id) {
    return {
        types: [
            DELETE_MASTER_DEFECT.LOADING,
            DELETE_MASTER_DEFECT.SUCCESS,
            DELETE_MASTER_DEFECT.ERROR,
        ],
        payload: {
            request: {
                method: "DELETE",
                url: `${endpoint}${tenant_id}/defects/${fruit_type}/${id}`,
            }
        }
    };
}

export function deleteAllTenantDefect(tenant_id, fruit_type) {
    return {
        types: [
            DELETE_MASTER_DEFECT.LOADING,
            DELETE_MASTER_DEFECT.SUCCESS,
            DELETE_MASTER_DEFECT.ERROR,
        ],
        payload: {
            request: {
                method: "DELETE",
                url: `${endpoint}${tenant_id}/defects/${fruit_type}/all`,
            }
        }
    };
}

export function updateTenantDefect(tenant_id, fruit_type, data) {
    return {
        types: [
            UPDATE_MASTER_DEFECT.LOADING,
            UPDATE_MASTER_DEFECT.SUCCESS,
            UPDATE_MASTER_DEFECT.ERROR,
        ],
        payload: {
            request: {
                method: "PUT",
                url: `${endpoint}${tenant_id}/defects/${fruit_type}/${data.id}`,
                data,
            }
        }
    };
}

export function importTenantDefect(tenant_id, fruit_type, data) {
    return {
        types: [
            UPDATE_MASTER_DEFECT.LOADING,
            UPDATE_MASTER_DEFECT.SUCCESS,
            UPDATE_MASTER_DEFECT.ERROR,
        ],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint}${tenant_id}/defects/${fruit_type}/import`,
                data,
            }
        }
    };
}

export function resetAllTenantDefect(tenant_id, data) {
    return {
        types: [
            UPDATE_MASTER_DEFECT.LOADING,
            LIST_ALL_MASTER_DEFECTS.SUCCESS,
            UPDATE_MASTER_DEFECT.ERROR,
        ],
        payload: {
            request: {
                method: "POST",
                url: `${endpoint}${tenant_id}/defects/reset-all`,
                data,
            }
        }
    };
}
