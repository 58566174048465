/** @jsxImportSource @emotion/react */
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button
} from "reactstrap";

import useConfig from "../../../actions/Tenants/config/configHook";
import { createWaste, listWaste } from "../../../actions/Waste/actions";
import MetaForm from "../../Forms/MetaForm";
import AvosModal, { modalResult } from "../RipeningTE/Modal";

export default function AddWasteForm() {
    const filter = useSelector((state) => state.waste.filter);
    const config = useConfig();
    const dispatch = useDispatch();

    const [modalOpen, setModal] = useState(false);
    const [waste, setWaste] = useState({
        number_of_boxes: "",
        country: null,
        waste_reason: null
    });

    const _addWaste = () => {
        dispatch(createWaste(waste)).then((response) => {
            if (!response.error) {
                setModal(false);
                dispatch(listWaste(filter));
            }
        });
    };

    const onClose = (submit) => {
        if (submit !== modalResult.save) {
            setModal(false);
            return;
        }
        _addWaste();
    };

    return (<div>
        <Button className="text-nowrap" color="primary" onClick={() => setModal(true)}><FontAwesomeIcon icon={faPlus} /> Log waste</Button>
        <AvosModal
            header="Log waste"
            onClose={onClose}
            isOpen={modalOpen}
            size="md"
        >
            <div>
                {config.waste_form && <MetaForm
                    grid={false}
                    meta={config.waste_form}
                    setValue={(field, value) => setWaste((object) => ({ ...object, [field]: value }))}
                    object={waste}
                    config={config} />}
            </div>
        </AvosModal>
    </div>);
}
