/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { createLayer } from "../../../actions/Layers/actions";
import { LAYER_TYPE, Layer } from "../../../actions/Layers/constants";
import useConfig from "../../../actions/Tenants/config/configHook";
import mq from "../../../constants/mediaqueries";
import LayerAutoComplete from "../../Forms/LayerAutoComplete";
import { CreateLayerModal } from "./Layer/CreateLayer";

export function defaultLayerHook() {
    const config = useConfig();
    // * Temporary disable fruit type detection by mini
    // const selected_mini = useSelector((state) => state.devices.selected_mini);
    // const fruitType = selected_mini && !config.can_do_manual_check ? selected_mini.fruit_type : config.default_fruit_type;
    const fruitType = config.default_fruit_type;
    const fruitConfig = config.get_fruit_type(fruitType);
    const defaultLayer: Layer = {
        label: "",
        fruit_type: fruitType,
        fruit_variety: fruitConfig.default_variety,
        type: LAYER_TYPE.PALLET,
    } as Layer;
    return defaultLayer;
}

export default function FindLayerBox() {
    const config = useConfig();
    const dispatch = useDispatch();
    const filter = useSelector<any, any>((state) => state.layers.filter);
    const defaultLayer = defaultLayerHook();
    const [layer, setLayer] = useState<Layer>(defaultLayer);
    const [layerModalOpen, setLayerModal] = useState(false);
    const navigate = useNavigate();

    const layer_config = config.get_layer_config(layer);

    useEffect(() => {
        if (filter.layer_type) {
            setLayer({ ...layer, type: filter.layer_type });
        }
    }, [filter.layer_type]);

    const _createLayer = () => {
        const layer_config = config.get_layer_config(layer);
        Promise.resolve(
            dispatch(createLayer(layer)),
        ).then((response) => {
            if (response.payload) {
                const layer = response.payload.data;
                navigate(`/layer/${layer.id}/action/${layer_config.start_action || "edit"}`);
            }
        });
    };

    const setValue = (field, value) => setLayer((object) => ({ ...object, [field]: value }));

    const onClose = () => setLayerModal(false);

    const setLayerSelect = (select) => {
        if (select.id) {
            navigate(`/layer/${select.id}`);
            return;
        }
        setLayer(select);
        setLayerModal(true);
    };

    if (!layer_config) {
        return null;
    }
    return <div>
        <CreateLayerModal
            isOpen={layerModalOpen ? { withScanner: false } : false}
            onClose={onClose}
            onSubmit={_createLayer}
            layer={layer}
            setLayerSelect={setLayerSelect}
            setValue={setValue}
            disableSaveButton={!layer.label || !layer.type}
        />
        <div className="d-flex align-items-center justify-content-between justify-content-lg-start">
            <div css={mq({ width: ["100%", "100%", "100%", "20rem"] })}>
                <LayerAutoComplete type={null} layer={layer} setLayer={setLayerSelect} label_format={layer_config.label_format} invalid_hint={layer_config.invalid_hint} />
            </div>
        </div>
    </div >;
}
