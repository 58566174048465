import { defineAction } from "redux-define";
import {
    ERROR, FILTER, LOADING, RESET,
    STATE,
    SUCCESS,
    TheApp
} from "../../constants/stateConstants";

export const layers = defineAction("layers", [LOADING, ERROR, SUCCESS], TheApp);
export const LIST = defineAction("LIST", [FILTER, LOADING, ERROR, SUCCESS, RESET], layers);
export const PARENTS = defineAction("PARENTS", [LOADING, ERROR, SUCCESS], layers);
export const CHILDREN = defineAction("CHILDREN", [LOADING, ERROR, SUCCESS], layers);
export const CHECKS = defineAction("CHECKS", [LOADING, ERROR, SUCCESS], layers);
export const GET = defineAction("GET", [LOADING, ERROR, SUCCESS], layers);
export const CREATE = defineAction("CREATE", [LOADING, ERROR, SUCCESS], layers);
export const UPDATE = defineAction("UPDATE", [LOADING, ERROR, SUCCESS], layers);
export const PATCH = defineAction("PATCH", [LOADING, ERROR, SUCCESS, STATE], layers);
export const DELETE = defineAction("DELETE", [LOADING, ERROR, SUCCESS], layers);
export const LOCATION_HISTORY = defineAction("LOCATION_HISTORY", [LOADING, ERROR, SUCCESS], layers);
export const GET_STAGE_COUNTER = defineAction("GET_STAGE_COUNTER", [LOADING, ERROR, SUCCESS], layers);

export const BROWSE_HISTORY = defineAction("BROWSE_HISTORY", [SUCCESS], layers);
export const SUB_CREATE = defineAction("SUB_CREATE", [LOADING, ERROR, SUCCESS], layers);
