import Field from "./Field";

class LayerField extends Field {
    field_type = "layer_field";

    backend_table = "layer";

}

export default LayerField;
