/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ReactElement, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Col, Container, Row
} from "reactstrap";
import { countLayerInStage, listLayers, updateFilter } from "../../../actions/Layers/actions";
import { LayerFilter } from "../../../actions/Layers/constants";
import useConfig from "../../../actions/Tenants/config/configHook";
import mq from "../../../constants/mediaqueries";
import { useFruitTypes } from "../../../utils/useUserPreferences";
import { useDebounce } from "../../Forms/utils";
import LayerList from "./LayerList";
import StageActions from "./StageActions";
import useSwitchTab from "./fields/useSwitchTab";

export const DataTestId = {
    TAB: "TAB",
    TAB_CONTENT: "TAB_CONTENT",
};

export default function StagesTabs() {
    const config = useConfig();
    const filter = useSelector<any, any>((state) => state.layers.filter);
    const layers = useSelector<any, any>((state) => state.layers);
    const dispatch = useDispatch();
    const selectedFruitTypes = useFruitTypes();
    // const user = useSelector<any, any>((state) => state.auth.user);
    // const tenant_id = user?.tenant?.tenant_id;

    const [queryMap, setQueryMap] = useState({} as { [stage: string]: any });
    const emptyQuery = useRef({});
    const selectedStageName = filter?.location || "all";
    const query = queryMap[selectedStageName] || emptyQuery.current;

    const [listLayersDebounced] = useDebounce(
        (value, updateGlobalState = true) => {
            const pickRelatedQuery = (value) => {
                const stageConfig = config.get_stage(value.location);
                const fields = stageConfig?.overview_filter_form?.map((i) => i.field) || [];
                return fields.reduce((acc, i) => ({ ...acc, [i.name]: value[i.name], }), {});
            };
            const selectedStageName = value?.location || "all";
            setQueryMap({ ...queryMap, [selectedStageName]: pickRelatedQuery(value) });
            if (updateGlobalState) {
                dispatch(updateFilter(value));
            }
        },
        (value) => dispatch(listLayers(value)),
        { debounceTime: 500, immediate: true }
    );

    const getFilterCleaner = () => {
        const oldStageConfig = config.get_stage(filter.location);
        const oldStageFields = oldStageConfig?.overview_filter_form?.map((i) => i.field) || [];
        return oldStageFields.reduce((acc, i) => ({ ...acc, [i.name]: undefined, }), {});
    };

    const onLocationRelatedFilterItemsChange = (locationRelatedFilters) => {
        const selectedStageName = locationRelatedFilters?.location || "all";
        const query = queryMap[selectedStageName] || emptyQuery.current;
        const filterCleaner = getFilterCleaner();
        listLayersDebounced({ offset: 0, ...filter, ...filterCleaner, ...query, ...locationRelatedFilters, fruit_type: selectedFruitTypes });
    };
    const switchTab = useSwitchTab({ onFilterBundleChange: onLocationRelatedFilterItemsChange });
    const tabs = config.stages.map((i) => ({
        title: i.text,
        subtitle: null,
        location: i.value,
        layer_type: i.layer_type,
        view_strategy: i.view_strategy,
        default_ordering: i.default_ordering,
        count: i.value === filter.location ? layers.items.count : null,
    }));

    useEffect(() => {
        dispatch(countLayerInStage());
    }, []);

    // switch to first tab if no tab is selected
    useEffect(() => {
        if (tabs && tabs.length > 0 && !filter.location) {
            switchTab(tabs[0]);
        }
    }, [tabs, filter.location]);

    const currentIndex = tabs.findIndex((i) => i.location === filter.location);
    const tabStyle = (index) => {
        const styles = css`text-align:center;background-color:#e5e8e5;`;
        const responsiveStyles = mq({
            minWidth: ["49%", "48%", "48%", "130px"],
            borderRadius: ["8px", "8px", "8px", "8px 8px 0px 0px"],
            borderBottom: ["0px", "0px", "0px", "2px solid #eee"],
        });
        if (currentIndex === index) {
            const activeStyle = css`font-weight: 900; background-color: #fff;`;
            const activeResponsiveStyle = mq({
                borderBottom: ["0px", "0px", "0px", "2px solid #fff"],
            });
            return [styles, responsiveStyles, activeResponsiveStyle, activeStyle];
        }
        const inActiveStyle = css`cursor:pointer`;
        return [styles, responsiveStyles, inActiveStyle];
    };

    return <Container className="pb-md-5 pb-3">
        <div>
            <Row className="justify-content-center py-3">
                <Col lg="12" >
                    <div className="py-5">
                        <div className="d-flex flex-wrap">
                            {
                                tabs.map((tab, index) => <div key={index} className="px-3 py-3 mb-3 me-0 me-lg-2  mb-lg-0" css={tabStyle(index)} onClick={() => switchTab(tab)} data-testid={`${DataTestId.TAB}${index}`}>
                                    <h6 className="mb-0 text-no-wrap fw-bold" css={css`opacity: ${currentIndex === index ? 1 : 0.5};`}>{tab.title}  <Count stage={tab as any} /> </h6>
                                    {tab.subtitle && <p className="text-muted py-10">{tab.subtitle}</p>}
                                </div>)
                            }
                        </div>
                        <div className="bg-white px-3 py-3" css={css`border-radius: 0px 8px 8px 8px;`} >
                            <StageActions
                                filter={{ ...filter, ...query, }}
                                setFilterItem={(field, value) => {
                                    listLayersDebounced({ offset: 0, ...filter, ...query, fruit_type: selectedFruitTypes, [field]: value }, false);
                                }}
                                onResetFilter={() => {
                                    const filterCleaner = getFilterCleaner();
                                    listLayersDebounced({ offset: 0, ...filter, ...filterCleaner, fruit_type: selectedFruitTypes });
                                }} />
                            <LayerList />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    </Container>;
}


function Count({ stage }: { stage: any }): ReactElement<any, any> {
    const per_stage = useSelector<any, any[]>((state) => state.layers.per_stage);
    const filter = useSelector<any, LayerFilter>((state) => state.layers.filter);
    let counted;
    if (stage.location === filter.location) {
        // count the layers for current filtered layer type
        counted = per_stage.find((i) => i.stage === stage.location && i.type === filter.layer_type);
    } else {
        // count the layer for default layer type for stage
        counted = per_stage.find((i) => i.stage === stage.location && i.type === stage.layer_type);
    }
    if (stage.location === "") {
        return <></>;
    }

    let value = "-";

    if (counted) {
        value = counted.count;
    }

    return <span className="opacity-60 ms-2">{value}</span>;
}
