/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import dayjs from "dayjs";
import { Badge, Col, Container, Row, Table } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "../../Pagination";
import { getNotifications, updateFilter } from "../../../actions/Mailbox/actions";
import { Loading } from "../../Helper/Loading";
import { formatDateTime } from "../Layers/fields/formatters";
import { validateRecipient } from "../Tenants/Forms/EmailRecipientsForm";
import AttachmentLink from "../../Helper/AttachmentLink";
import { RootState } from "../../../reducers";
import { MailboxState } from "../../../actions/Mailbox/reducer";

const MailStatus = {
    New: "new",
    InProgress: "in_progress",
    Sent: "sent",
    Failed: "failed",
    Retry: "retry"
};

const BadgeColors = {
    [MailStatus.New]: "primary",
    [MailStatus.InProgress]: "warning",
    [MailStatus.Sent]: "success",
    [MailStatus.Failed]: "danger",
};

const getBadgeColor = (status: string) => {
    const color = BadgeColors[status];
    return color || "secondary";
};

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

const StatusBadge = ({ status }) => {
    status = status === MailStatus.Retry ? MailStatus.InProgress : status;
    const badgeColor = getBadgeColor(status);
    const statusToPrint = capitalize(status.replace("_", " "));
    return <Badge color={badgeColor}>{statusToPrint}</Badge>;
};

const Mailbox = () => {
    const dispatch = useDispatch();
    const items = useSelector((state: any) => state.mailbox.items);
    const isLoading = useSelector((state: any) => state.mailbox.isLoading);
    const filter = useSelector<RootState, MailboxState["filter"]>((state) => state.mailbox.filter);

    useEffect(() => {
        dispatch(getNotifications(filter));
    }, [filter]);

    const onPageChanged = (data) => {
        const { currentPage, pageLimit } = data;
        const offset = Math.max((currentPage - 1) * pageLimit, 0);
        if (filter.offset !== offset) {
            dispatch(updateFilter({ ...filter, offset }));
        }
    };

    const updateLimit = (limit) => {
        dispatch(updateFilter({ ...filter, limit }));
    };

    const MailRecipientGroup = ({ group, title }) => <div>
        <strong>{title}:</strong>
        {group.map((recipient, i) => showIndividual(recipient) && <span key={i}>{recipient?.displayName} &lt;{recipient?.address}&gt;</span>)}
    </div>;

    const showGroup = (group) => group && Array.isArray(group) && group.length > 0;
    const showIndividual = (recipient) => recipient && validateRecipient(recipient);

    const MailRecipients = ({ recipients = {} as any }) => {
        const { to, cc, bcc } = recipients;
        return <div>
            {showGroup(to) && <MailRecipientGroup group={to} title={"To"} />}
            {showGroup(cc) && <MailRecipientGroup group={cc} title={"Cc"} />}
            {showGroup(bcc) && <MailRecipientGroup group={bcc} title={"Bcc"} />}
        </div>;
    };

    return <Container className="py-md-5 py-3">
        <div className="pt-4 d-flex flex-column" css={css`min-height: 50vh;`} >
            {isLoading && <Loading />}
            {!isLoading
                && <Table size="sm" responsive={true} borderless={true}>
                    <thead>
                        <tr>
                            <th>Subject</th>
                            <th>Layer</th>
                            <th>Supplier</th>
                            <th>Report</th>
                            <th>Recipients</th>
                            <th>Status</th>
                            <th>Created</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.results.map((item) => {
                            const { supplier, layer } = item.attributes;
                            return <tr key={item.id}>
                                <td>{item.subject}</td>
                                <td><Link to={`/layer/${layer.id}`}>{layer.label}</Link></td>
                                <td>{supplier.name}</td>
                                <td>
                                    {item.attachment_urls.map((url, i) => <AttachmentLink key={i} url={url} />)}
                                </td>
                                <td><MailRecipients recipients={item.recipients} /></td>
                                <td><StatusBadge status={item.status} /></td>
                                <td>{formatDateTime(dayjs(item.created))}</td>
                            </tr>;
                        })}
                    </tbody>
                </Table>
            }
        </div>
        <Row className={`align-items-center py-0 mt-auto ${isLoading || !items?.results ? "invisble" : ""}`}>
            <Col md="4" className="py-5">
                <Pagination totalRecords={items.count} pageLimit={filter.limit} pageNeighbours={1} onPageChanged={onPageChanged} />
            </Col>
            <Col md="4" className="py-5 text-center">
                <small>
                    {items.results.length} of {items.count}
                </small>
            </Col>
            <Col md="4" className="py-5 text-md-end text-center col-sm">
                <div className="btn-group-sm " role="group" aria-label="limit">
                    {[5, 25, 50, 100].map((i) => (<button key={i} type="button" className={`ms-1 btn btn-outline-dark ${i === filter.limit ? "active" : ""}`} onClick={() => updateLimit(i)}>{i}</button>))}
                </div>
            </Col>
        </Row>
    </Container>;
};


export default Mailbox;
