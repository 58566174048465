/** @jsxImportSource @emotion/react */

import FilterableConfigList from "./FilterableConfigList";


export const categoryForm = [
    {
        label: "Greater then or equal to",
        name: "gte",
        type: "number",
    },
    {
        label: "Less then",
        name: "lt",
        type: "number",
    },
    {
        label: "Greater then",
        name: "gt",
        type: "number",
    },
    {
        label: "Less then or equal to",
        name: "lte",
        type: "number",
    },
    {
        label: "Label",
        name: "label",
        type: "text",
    },
    {
        label: "Color",
        name: "color",
        type: "text",
        description: "Color for the category in hex format. For example #FFA500. Used in some components."
    },
];

const defaultField = {
    field: {
        gte: false,
        lt: 16,
        label: "Overripe",
        color: "#FFA500"
    }
};
export default function CategoryConfigForm({ name, title, fields, description, setArray, useDisplayFilter = false }) {
    const getFieldTitle = (object) => <span>{object.label}</span>;
    const getFieldForm = () => categoryForm;
    return <FilterableConfigList
        useDisplayFilter={useDisplayFilter as false | string[]}
        name={name}
        title={title}
        description={description}
        fields={fields}
        setArray={setArray}
        defaultField={defaultField}
        getFieldForm={getFieldForm}
        getFieldTitle={getFieldTitle} />;

}
