import useConfig from "../../../actions/Tenants/config/configHook";
import ViewStrategyModal from "./ViewStrategyModal";
import DownloadCsvModel from "./DownloadCsvModel";
import LayerFilter from "./LayerFilter";

export interface StageActionsProps {
    filter: any;
    setFilterItem: (field: string, value: any) => void;
    onResetFilter: () => void;
}

export default function StageActions({ filter, setFilterItem, onResetFilter }: StageActionsProps) {
    const config = useConfig();
    const stage = config.get_stage(filter.location);

    return <div className="d-flex align-items-center">
        <ViewStrategyModal />
        <DownloadCsvModel stage={stage} />
        <LayerFilter filter={filter} setFilterItem={setFilterItem} onReset={onResetFilter}/>
    </div>;
}
