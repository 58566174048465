/** @jsxImportSource @emotion/react */
import { useMsal } from "@azure/msal-react";
import { css } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Button, Col, Container, Row
} from "reactstrap";
import useConfig from "../../../actions/Tenants/config/configHook";
import { loginRequest } from "../../../authConfig";
import { IS_DEMO } from "../../../constants/urls";
import mrAvos from "../../../img/avos-eye.jpg";
import { Loading } from "../../Helper/Loading";
import { toast } from "../../../utils/toast";

export default function Login() {
    const { instance } = useMsal();
    const isLoading = useSelector((state) => state.auth.isLoading);
    const authenticated = useSelector((state) => state.auth.authenticated);
    const [redirecting, isRedirecting] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const timer = useRef();
    const config = useConfig();

    // we start the screen in loading state so that we have smoother transition after redirecting from AAD
    useEffect(() => {
        if (!isLoading && !redirecting && !authenticated) {
            timer.current = setTimeout(() => setShowLoader(false), 2000);
        } else {
            clearTimeout(timer.current);
        }
        return () => clearTimeout(timer.current);
    }, [redirecting, authenticated, isLoading]);

    // store redirect_to for later referene
    useEffect(() => {
        if (location.search) {
            const search = new URLSearchParams(location.search);
            const redirect_to = search.get("redirect_to");
            if (redirect_to) {
                localStorage.setItem("avos_login_redirect", redirect_to);
            }
        }
    }, [location.search]);

    // after login send to root or redirect_to
    useEffect(() => {
        if (authenticated) {
            isRedirecting(true);
            const redirect_to = localStorage.getItem("avos_login_redirect");
            if (redirect_to) {
                localStorage.removeItem("avos_login_redirect");
            }
            navigate(redirect_to || config.home_page);
        }
    }, [authenticated]);

    const redirect = async () => {
        try {
            localStorage.removeItem("msal.interaction.status");
            await instance.loginRedirect(loginRequest);
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
            toast.error(`Cannot request login redirect url: ${err.message}`);
        }
    };


    if (showLoader || redirecting) {
        return <Loading/>;
    }
    return (
        <Container >
            <Row className="align-items-center justify-content-center text-center" css={css`min-height: 82vh`} >
                <Col xs="12" md="6" lg="4" >
                    <div className="pb-2 text-center">
                        <img src={mrAvos} alt="Meneer AVOS" css={css`max-width: 11rem;width: 50%;`} />
                    </div>
                    <h4 className="py-5 mb-0 text-center"><b>Welcome!</b><br></br>Login to continue with AVOS.</h4>
                    {IS_DEMO && <Button className="btn btn-primary btn-lg" onClick={() => navigate("/")}>Start DEMO</Button> }
                    {!IS_DEMO && <Button className="btn btn-primary btn-lg" onClick={() => redirect()}>Login with Microsoft</Button>
                    }
                </Col>
            </Row>
        </Container>
    );
}
