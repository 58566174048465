import { useMsal } from "@azure/msal-react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { getUser } from "../../../actions/Auth/actions";
import { ConfigProvider } from "../../../actions/Tenants/config/configHook";
import { scopes } from "../../../authConfig";
import { IS_DEMO } from "../../../constants/urls";
import { Loading } from "../../Helper/Loading";
import DefaultLayout from "../Layout/DefaultLayout";
import FullscreenLayout from "../Layout/FullscreenLayout";


interface RequireAuthPropsType {
    element: React.ReactNode;
    fullscreen?: boolean;
    disableRefresh?: boolean;

}

export function RequireAuth({ element, fullscreen = false, disableRefresh = false }: RequireAuthPropsType) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [now, setNow] = useState(Date.now());
    const authenticated = useSelector((state: any) => state.auth.authenticated);
    const { instance } = useMsal();
    const account = instance.getActiveAccount();
    const intervalRef = useRef<null | number>(null);

    const accessTokenRequest = {
        scopes,
        account,
        redirectUri: "/blank.html",
    };
    const redirectToLogin = () => navigate(`/login?redirect_to=${location.pathname}${location.search}`);
    useEffect(() => {
        // AUTH scheme is overwritten in demo
        if (IS_DEMO) {
            dispatch(getUser() as any);
            return;
        }

        if (account) {
            // This is an attempt to use a refresh token
            instance.acquireTokenSilent(accessTokenRequest as any).then((response) => {
                if (response) {
                    dispatch(getUser() as any);
                }
            }).catch((error) => {
                console.error(error); // eslint-disable-line no-console
                redirectToLogin();
            });
        } else {
            redirectToLogin();
        }
    }, [account ? account.homeAccountId : null, instance, now, IS_DEMO]); // refer to home accountId to prevent infinite loop

    useEffect(() => {

        // * dont refresh tokens in demo environemt or if specifcily disabled
        // * reset interval if previously set
        if (!IS_DEMO && !disableRefresh) {
            intervalRef.current = setInterval(() => {
                setNow(Date.now());
            }, 2 * 60 * 1000) as unknown as number;
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        };

    }, [IS_DEMO, disableRefresh]);


    // What do we need to render? If use is not authenticated always show loading indicator on this component
    const getChildComponent = () => {
        if ((!account && !IS_DEMO) || !authenticated) {
            return <Loading />;
        }
        return element;
    };

    // Fullscreen of default layout?
    const getTemplate = (el) => {
        if (fullscreen) {
            return <FullscreenLayout>{el}</FullscreenLayout>;
        }
        return <DefaultLayout>{el}</DefaultLayout>;
    };
    // wrap this component in the config provider
    return <ConfigProvider refreshInterval={disableRefresh ? false : 6 * 60 * 1000} >{getTemplate(getChildComponent())}</ConfigProvider>;
}

