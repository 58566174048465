import { defineAction } from "redux-define";
import {
    ERROR,
    FILTER,
    LOADING,
    SUCCESS,
    TheApp,
} from "../../constants/stateConstants";

export const tenants = defineAction("tenants", [LOADING, ERROR, SUCCESS], TheApp);
export const LIST = defineAction("LIST", [FILTER, LOADING, ERROR, SUCCESS], tenants);
export const GET = defineAction("GET", [LOADING, ERROR, SUCCESS], tenants);
export const CREATE = defineAction("CREATE", [LOADING, ERROR, SUCCESS], tenants);
export const UPDATE = defineAction("UPDATE", [LOADING, ERROR, SUCCESS], tenants);
export const DELETE = defineAction("DELETE", [LOADING, ERROR, SUCCESS], tenants);

// form options
export const LIST_FIELD_OPTIONS = defineAction("LIST_FIELD_OPTIONS", [LOADING, ERROR, SUCCESS], tenants);
export const LIST_ALL_FIELD_OPTIONS = defineAction("LIST_ALL_FIELD_OPTIONS", [LOADING, ERROR, SUCCESS], tenants);
export const CREATE_FIELD_OPTION = defineAction("CREATE_FIELD_OPTION", [LOADING, ERROR, SUCCESS], tenants);
export const DELETE_FIELD_OPTION = defineAction("DELETE_FIELD_OPTION", [LOADING, ERROR, SUCCESS], tenants);
export const UPDATE_FIELD_OPTION = defineAction("UPDATE_FIELD_OPTION", [LOADING, ERROR, SUCCESS], tenants);

// tenant configs
export const GET_CONFIG = defineAction("GET_CONFIG", [LOADING, ERROR, SUCCESS], tenants);
export const LIST_CONFIGS = defineAction("LIST_CONFIGS", [LOADING, ERROR, SUCCESS], tenants);
export const LIST_ALL_CONFIGS = defineAction("LIST_ALL_CONFIGS", [LOADING, ERROR, SUCCESS], tenants);
export const CREATE_CONFIG = defineAction("CREATE_CONFIG", [LOADING, ERROR, SUCCESS], tenants);
export const DELETE_CONFIG = defineAction("DELETE_CONFIG", [LOADING, ERROR, SUCCESS], tenants);
export const UPDATE_CONFIG = defineAction("UPDATE_CONFIG", [LOADING, ERROR, SUCCESS], tenants);
export const UPDATE_CONFIG_STATE = defineAction("UPDATE_CONFIG_STATE", [LOADING, ERROR, SUCCESS], tenants);
export const LIST_ALL_CONFIG_REVISIONS = defineAction("LIST_ALL_CONFIG_REVISIONS", [LOADING, ERROR, SUCCESS], tenants);


// tenant defects
export const LIST_MASTER_DEFECTS = defineAction("LIST_MASTER_DEFECTS", [LOADING, ERROR, SUCCESS], tenants);
export const LIST_ALL_MASTER_DEFECTS = defineAction("LIST_ALL_MASTER_DEFECTS", [LOADING, ERROR, SUCCESS], tenants);
export const CREATE_MASTER_DEFECT = defineAction("CREATE_MASTER_DEFECT", [LOADING, ERROR, SUCCESS], tenants);
export const DELETE_MASTER_DEFECT = defineAction("DELETE_MASTER_DEFECT", [LOADING, ERROR, SUCCESS], tenants);
export const UPDATE_MASTER_DEFECT = defineAction("UPDATE_MASTER_DEFECT", [LOADING, ERROR, SUCCESS], tenants);
