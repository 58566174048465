/* eslint-disable react/jsx-no-undef */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import useConfig from "../../../actions/Tenants/config/configHook";
import Header from "../Layout/Header";
import StagesTabs from "./StagesTabs";
import ScanLayerButton from "../Layers/ScanLayerButton";

export default function LayerStart() {
    const config = useConfig();
    const navigate = useNavigate();
    useEffect(() => {
        if (config.temp_no_layers) {
            navigate(config.home_page);
        }
    }, [config.temp_no_layers]);

    if (config.temp_no_layers) {
        return null;
    }
    return <div>
        <Header blueBackground={true} showLayerFilters={true} />
        {config.mock_signals && <Container>
            <Row className="py-4">
                <h3 className="fw-bold"><strong>{"Today's focus"}</strong></h3>
                <Col md="12"><div className="d-flex pt-3">
                    {config.mock_signals.map((i, index) => <div key={index} css={css`border-radius:8px;`} className={`p-4 me-4 bg-white card-alert --${i.flag}`} >
                        <h6 className="mb-0"><b>{i.title}</b></h6>
                        <div className="pb-2">{i.description}</div>
                        <Button className="p-2" color={i.color} onClick={() => navigate(i.url)}>Take action</Button>
                    </div>)}
                </div></Col>
            </Row>
        </Container>}
        <Container className="md-5 pt-5">
            <ScanLayerButton />
        </Container>
        <StagesTabs />
    </div>;
}
