import { AnyAction } from "redux";
import { PATCH } from "./actionsTypes";


export interface UserPreferences {
    fruit_types: string[];
}

const endpoint = "users/";

export function updatePreferences(data: UserPreferences) {
    return {
        types: [PATCH.LOADING, PATCH.SUCCESS, PATCH.ERROR],
        payload: {
            request: {
                method: "PATCH",
                url: `${endpoint}preferences/`,
                data,
            },
        },
    } as unknown as AnyAction;
}
