/** @jsxImportSource @emotion/react */
/* eslint-disable react/no-unused-prop-types */
import { Html5QrcodeResult, Html5QrcodeScanner, Html5QrcodeScannerState, Html5QrcodeSupportedFormats } from "html5-qrcode";
import { useEffect, useRef, useState, useCallback } from "react";

import { faBarcodeRead } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, FormGroup, Input, Label, FormText } from "reactstrap";
import { handlePatternValidation } from "../Forms/MetaForm";
import { BarcodeFilter } from "../../actions/Tenants/config/constantsTyped";

// QR code scanner properties
export interface Html5QrcodePluginProps {
    fps: number;
    verbose?: boolean;
    qrbox: number;
    aspectRatio?: number;
    disableFlip: boolean;
    qrCodeSuccessCallback: (decodedText: string, decodedResult: Html5QrcodeResult) => void;
    qrCodeErrorCallback?: () => void;
    barcode_filter?: BarcodeFilter;
}

// Creates the configuration object for Html5QrcodeScanner.
export const createConfig = ({
    fps,
    qrbox,
    aspectRatio,
    disableFlip,
    formatsToSupport,
}: Pick<Html5QrcodePluginProps, "fps" | "qrbox" | "aspectRatio" | "disableFlip"> & { formatsToSupport?: Html5QrcodeSupportedFormats[] }) => {
    const config: any = {};
    if (fps) config.fps = fps;
    if (qrbox) config.qrbox = qrbox;
    if (aspectRatio) config.aspectRatio = aspectRatio;
    if (disableFlip !== undefined) config.disableFlip = disableFlip;
    if (formatsToSupport) config.formatsToSupport = formatsToSupport;
    return config;
};

export const Html5QrcodePlugin = ({
    fps = 10,
    qrbox = 250,
    aspectRatio,
    verbose = false,
    disableFlip = false,
    qrCodeSuccessCallback,
    qrCodeErrorCallback,
    barcode_filter,
}: Html5QrcodePluginProps) => {
    const { filter, formatsToSupport } = typeof barcode_filter === "object" ? barcode_filter : { filter: barcode_filter, formatsToSupport: undefined };
    const html5QrcodeScanner = useRef<Html5QrcodeScanner | null>(null);
    const qrcodeRegionId = useRef(`qrcode-region-${Math.random().toString(36).substring(7)}`);

    useEffect(() => {
        if (!html5QrcodeScanner.current) {
            const config = createConfig({ fps, qrbox, aspectRatio, disableFlip, formatsToSupport });
            html5QrcodeScanner.current = new Html5QrcodeScanner(qrcodeRegionId.current, config, verbose);
        }
        html5QrcodeScanner.current.render((decodedText, decodedResult) => {
            // if it is an Android device and decodedText starts with "]C1", remove the first character
            if (navigator.userAgent.toLowerCase().match(/android/i) && decodedText.startsWith("]C1")) {
                decodedText = decodedText.substring(3);
            }
            const filteredValue = filter?.(decodedText);
            if (filteredValue) {
                // pause the scanner after a successful scan
                if (html5QrcodeScanner.current?.getState() === Html5QrcodeScannerState.SCANNING) {
                    html5QrcodeScanner.current.pause(true);
                }
                qrCodeSuccessCallback(filteredValue, decodedResult);
            }
        }, qrCodeErrorCallback);

        // Cleanup function when the component unmounts
        return () => {
            if (html5QrcodeScanner.current?.getState() === Html5QrcodeScannerState.SCANNING) {
                html5QrcodeScanner.current.pause(true);
            }
            html5QrcodeScanner.current?.clear().catch((error) => {
                console.error("Failed to clear html5QrcodeScanner.", error); // eslint-disable-line no-console
            });
        };
    }, [fps, qrbox, aspectRatio, disableFlip, formatsToSupport, verbose, qrCodeSuccessCallback, qrCodeErrorCallback, filter]);

    return <div id={qrcodeRegionId.current} />;
};

// Wrapper for QR code scanner
export interface QRCodeScannerProps {
    onScan: (decodedText: string) => void;
    barcode_filter?: BarcodeFilter;
}

const QRCodeScanner: React.FC<QRCodeScannerProps> = ({ onScan, barcode_filter }) => {
    const handleScan = useCallback(
        (decodedText: string) => {
            onScan(decodedText);
        },
        [onScan]
    );

    return (
        <Html5QrcodePlugin
            fps={10}
            qrbox={250}
            disableFlip={false}
            qrCodeSuccessCallback={handleScan}
            barcode_filter={barcode_filter}
        />
    );
};

export default QRCodeScanner;

// Text QR code scanner properties
export interface TextQRcodeScannerProps {
    item: any;
    object: any;
    setValue: (name: string, value: any) => void;
    barcode_filter?: BarcodeFilter;
}

// Component for meta form input
export const TextQRcodeScanner: React.FC<TextQRcodeScannerProps> = ({ item, object, setValue, barcode_filter }) => {
    const [open, setOpen] = useState(false);

    const handleScan = useCallback(
        (result: string) => {
            setValue(item.name, result);
            setOpen(false);
        },
        [item.name, setValue]
    );

    const onBarcodeButtonClick = useCallback(() => {
        if (barcode_filter) {
            setOpen(!open);
        } else {
            alert("Miss configuration: Barcode filter not set!"); // eslint-disable-line no-alert
        }
    }, [barcode_filter, open]);

    return (
        <FormGroup>
            <Label>{item.label}</Label>
            <div style={{ display: open ? "block" : "none" }}>
                <QRCodeScanner onScan={handleScan} barcode_filter={barcode_filter} />
            </div>
            <FormGroup>
                <Input
                    type={item.type || "text"}
                    name={item.name}
                    id={item.name}
                    value={object[item.name]}
                    onChange={(e) => setValue(item.name, e.target.value)}
                    invalid={!handlePatternValidation(item, object)}
                />
                {!handlePatternValidation(item, object) && item.invalid_hint && (
                    <FormText>{item.invalid_hint}</FormText>
                )}
                <Button
                    color="primary"
                    className="text-nowrap"
                    css={{
                        position: "relative",
                        float: "right",
                        top: "-2.4em",
                    }}
                    onClick={onBarcodeButtonClick}
                >
                    <FontAwesomeIcon icon={faBarcodeRead} />
                </Button>
            </FormGroup>
        </FormGroup>
    );
};
