/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { layer } from "@fortawesome/fontawesome-svg-core";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Row, Table } from "reactstrap";
import { listLayers } from "../../../actions/Layers/actions";
import { applyDisplayFilterLayerFields } from "../../../actions/Tenants/config/applyDisplayFilter";
import useConfig, { useTenantFormOptions } from "../../../actions/Tenants/config/configHook";
import { getFilledArrayOrDefault } from "../../../utils";
import { useFruitTypes } from "../../../utils/useUserPreferences";
import LayerSortableHeader from "../../Helper/LayerSortableHeader";
import { Loading } from "../../Helper/Loading";
import Pagination from "../../Pagination";

export const DataTestId = {
    TAB: "TAB",
    TAB_CONTENT: "TAB_CONTENT",
};

export default function LayerList() {
    return <LayerTable />;
}


function TH({ field }) {
    return <td className="text-muted text-start text-nowrap fw-bold py-1 px-2 " css={css`color: black !important;background: #e5e8e5!important; border-right: 1px solid white;`}>
        <LayerSortableHeader field={field} />
    </td>;
}

TH.propTypes = {
    field: PropTypes.object,

};

function TD({ children }) {
    return <td className="text-start text-nowrap px-2 text-capitalize">{children}</td>;
}

TD.propTypes = {
    children: PropTypes.any,
};


export function LayerTable() {
    const isLoading = useSelector<any, any>((state) => state.layers.isLoading);
    const layers = useSelector<any, any>((state) => state.layers.items);
    const config = useConfig();
    const dispatch = useDispatch();
    const filter = useSelector<any, any>((state) => state.layers.filter);
    const navigate = useNavigate();
    const form_options = useTenantFormOptions();
    const stage = config.get_stage(filter.location);
    const selectedFruitTypes = useFruitTypes();

    // Overwrite hardcoded fields with managed fields from the DB
    if (!layers || !stage) {
        return null;
    }
    const fields = applyDisplayFilterLayerFields(stage?.layer_overview_fields || [], {
        fruit_type: getFilledArrayOrDefault(selectedFruitTypes).length === 1 ? selectedFruitTypes[0] : null, // * only enable fruit type filter on fields when exactly one fruit type is selected
        layer_type: filter.layer_type
    });

    const updateLimit = (limit) => {
        dispatch(listLayers({ ...filter, offset: 0, limit, fruit_type: selectedFruitTypes }));
    };

    const onPageChanged = (data) => {
        const { currentPage, pageLimit } = data;
        const offset = Math.max((currentPage - 1) * pageLimit, 0);
        if (filter.offset !== offset) {
            dispatch(listLayers({ ...filter, offset, fruit_type: selectedFruitTypes }));
        }
    };
    // sometimes needed when switching tenant
    if (!fields) {
        return null;
    }
    const values = (p) => fields.map((i, index) => <TD key={index}>{i.set_value({
        config,
        layer: p,
        check: p.latest_check, // * To be used in check tables and layer overview
        fruit: null, // * Fruit list is not available on overview screen
        children: null, // * children is only available on layer index page
        form_options // * used to translate form values to labels
    }).display_component()}</TD>);

    const header = () => fields.map((i, index) => <TH key={index} field={i}></TH>);

    return <div className="pt-4 d-flex flex-column" css={css`min-height: 70vh;`} >
        <div >
            {isLoading && <Loading />}
            {!isLoading && <Table size="sm" responsive={true} borderless={true}>
                <thead>
                    <tr>{header()}</tr>
                </thead>
                <tbody>
                    {layers.results.map((i, index) => <tr key={index} className="clickable" title={i.id} onClick={() => navigate(`/layer/${i.id}`)}>{values(i)}</tr>)}
                </tbody>

            </Table >}
        </div>
        <Row className={`align-items-center py-0 mt-auto ${isLoading || !(layer as any).results ? "invisble" : ""}`}>
            <Col md="4" className="py-5">
                <Pagination totalRecords={layers.count} pageLimit={filter.limit} pageNeighbours={1} onPageChanged={onPageChanged} />
            </Col>
            <Col md="4" className="py-5 text-center">
                <small>
                    {layers.results.length} of {layers.count}
                </small>
            </Col>
            <Col md="4" className="py-5 text-md-end text-center col-sm">
                <div className="btn-group-sm " role="group" aria-label="limit">
                    {[5, 25, 50, 100].map((i) => (<button key={i} type="button" className={`ms-1 btn btn-outline-dark ${i === filter.limit ? "active" : ""}`} onClick={() => updateLimit(i)}>{i}</button>))}
                </div>
            </Col>
        </Row>
    </div>;
}
