import { useDispatch, useSelector } from "react-redux";
import { listLayers } from "../../../actions/Layers/actions";
import { LayerFilter } from "../../../actions/Layers/constants";
import useConfig from "../../../actions/Tenants/config/configHook";
import { UserPreferences, updatePreferences } from "../../../actions/Users/actions";
import { useFruitTypes } from "../../../utils/useUserPreferences";
import { CheckboxButtons } from "../../Forms/CheckboxButtons";
import { useListLayersDebounced } from "./utils";

export default function FruitFilter() {
    const dispatch = useDispatch<any>();
    const config = useConfig();
    const filter = useSelector<any, any>((state) => state.layers.filter);
    const userPreferences = useSelector<any, UserPreferences>((state) => state.auth.user.preferences);
    const allOptions = useSelector((state: any) => state?.tenants?.options_by_field);
    const fruitTypeOptions = allOptions?.[config.tenant_id]?.fruit_type || [];
    const selectedFruitTypes = useFruitTypes();
    const [listLayersDebounced] = useListLayersDebounced(
        (filter: LayerFilter) => {
            const fruit_types = filter.fruit_type || [];
            dispatch(updatePreferences({ ...userPreferences, fruit_types })).then(() => {
                dispatch(listLayers(filter));
            });
        }
    );

    const onFruitTypeChange = (fruit_types: (string | number | boolean)[]) => {
        listLayersDebounced({ ...filter, offset: 0, fruit_type: fruit_types });
    };

    return <div>
        <CheckboxButtons
            color="secondary"
            return_array={true}
            options={fruitTypeOptions.map((option) => ({
                ...option,
                className: "mx-1",
            }))}
            selectedOptions={selectedFruitTypes}
            onChange={onFruitTypeChange}
        />
    </div>;
}
