/** @jsxImportSource @emotion/react */
import { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { css } from "@emotion/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Parser } from "@json2csv/plainjs";
import { flatten } from "@json2csv/transforms";
import dayjs from "dayjs";
import DatePicker from "../../Forms/DatePicker";
import { getAvosApiClient } from "../../../utils/useAvosApiHook";
import { Loading } from "../../Helper/Loading";
import { Layer } from "../../../actions/Layers/constants";

interface DownloadQuery {
    created_from: Date | null;
    created_to: Date | null;
    meta_fields: string | null;
}

interface DownloadOptions {
    delimiter: string;
}

const DownloadCsvModel = ({ stage }) => {

    const isLoading = useSelector((state: any) => state.layers.isLoading);
    const layers = useSelector((state: any) => state.layers.items);
    const [downloadQuery, setDownloadQuery] = useState<DownloadQuery>({ created_from: null, created_to: null, meta_fields: null });
    const [isOpenDownloadAllPages, setDownloadAllPagesModal] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [layersNotFound, setLayersNotFound] = useState(false);

    const downlaodFields = stage?.download_csv_fields?.map((i) => i.field) || [];
    const emptyRequest = Object.keys(downloadQuery).every((key) => downloadQuery[key] === null);

    const downloadAllPages = () => {
        setDownloading(true);
        getAvosApiClient(`/layers/layers_for_download`, { params: { ...downloadQuery, meta_fields: downlaodFields.map((i) => i.name).join(",") } }).then(({ data }) => {
            if (data.length === 0) {
                setLayersNotFound(true);
            } else if (data.length > 0) {
                downloadLayersAsCSV(data);
                setDownloadAllPagesModal(false);
                setDownloadQuery({ created_from: null, created_to: null, meta_fields: null });
                setLayersNotFound(false);
            }
        });
        setDownloading(false);
    };

    const downloadLayersAsCSV = (layers: Layer[]) => {
        const opts = {
            delimiter: ";",
        };

        downloadCsvFile(opts, layers);
    };


    const downloadCSV = (layers: Layer[]) => {
        const opts = {
            delimiter: ";",
            transforms: [
                (item) => {
                    delete item.tenant_id;
                    delete item.images;
                    if (item.latest_check) {
                        delete item.latest_check.tenant_id;
                    }
                    if (item.latest_location) {
                        delete item.latest_location.layer_id;
                        delete item.latest_location.id;
                        delete item.latest_location.action_id;
                    }
                    return {
                        ...item,
                        children: item.children.map((i) => i.label).join(","),
                        parents: item.parents.map((i) => i.label).join(",")
                    };
                },
                flatten(),
            ]

        };
        downloadCsvFile(opts, layers);
    };


    const downloadCsvFile = (opts: DownloadOptions, layers: Layer[]) => {
        try {

            const parser = new Parser(opts);
            const csv = parser.parse(layers);

            const blob = new Blob([csv], { type: "text/csv" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            const datetime = dayjs().format("Hm-DDMMYYYY");
            link.download = `export-${datetime}.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (err) {
            console.error(err); // eslint-disable-line no-console
        }
    };

    return (
        <div className="pb-2 pb-md-0 pe-2">
            {downlaodFields.length > 0
                ? <Button color="light" onClick={() => setDownloadAllPagesModal(true)}>
                    <FontAwesomeIcon icon={faDownload} />
                </Button>
                : <Button color="light" disabled={downloading || (isLoading && layers.count > 0 && layers.results && layers.results.length > 0)} onClick={() => downloadCSV(layers.results)}>
                    <FontAwesomeIcon icon={faDownload} />
                </Button>
            }
            <Modal size="md" isOpen={isOpenDownloadAllPages} toggle={() => setDownloadAllPagesModal(false)}>
                <ModalHeader toggle={() => setDownloadAllPagesModal(false)}>Download</ModalHeader>
                <ModalBody>
                    <div className="pb-2 mb-3">
                        <Label>Date</Label>
                        <div className="d-flex align-items-center">
                            <DatePicker value={downloadQuery.created_from} onChange={(value) => setDownloadQuery((downloadQuery) => ({ ...downloadQuery, created_from: value }))} />
                            <div className="px-2">to</div>
                            <DatePicker value={downloadQuery.created_to} onChange={(value) => setDownloadQuery((downloadQuery) => ({ ...downloadQuery, created_to: value }))} />
                        </div>
                    </div>
                    {layersNotFound && <div className="pb-2 mb-3" css={css`color: #f0ad4e;`}>Results not found</div>}
                    {downloading && <Loading />}
                </ModalBody>
                <ModalFooter>
                    <Button color="light" className="me-2" disabled={downloading || emptyRequest} onClick={() => downloadAllPages()}>
                        <FontAwesomeIcon icon={faDownload} />
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default DownloadCsvModel;

DownloadCsvModel.propTypes = {
    stage: PropTypes.object,
};
