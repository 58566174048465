import { toast } from "../../utils/toast";
import {
    CREATE,
    CREATE_CONFIG,
    CREATE_FIELD_OPTION,
    CREATE_MASTER_DEFECT,
    DELETE,
    DELETE_CONFIG,
    DELETE_FIELD_OPTION,
    DELETE_MASTER_DEFECT,
    GET,
    GET_CONFIG,
    LIST,
    LIST_ALL_CONFIGS,
    LIST_ALL_CONFIG_REVISIONS,
    LIST_ALL_FIELD_OPTIONS,
    LIST_ALL_MASTER_DEFECTS,
    LIST_FIELD_OPTIONS,
    LIST_MASTER_DEFECTS,
    UPDATE,
    UPDATE_CONFIG,
    UPDATE_CONFIG_STATE,
    UPDATE_FIELD_OPTION,
    UPDATE_MASTER_DEFECT
} from "./actionsTypes";
import { defaultFilter, defaultTenant } from "./constants";

const tenants = {
    items: false,
    isLoading: false,
    isUpdating: false,
    configRevisions: [],
    current: defaultTenant,
    filter: defaultFilter,
    options_by_field: {},
    defects_by_fruit_type: {},
    configs: {}
};

export default (state = tenants, action) => {
    switch (action.type) {
    case LIST.FILTER:
        return { ...state, filter: action.payload };

    case LIST.LOADING:
        return { ...state, isLoading: true, filter: action.payload.request.filter_object };

    case GET.LOADING:
        return { ...state, current: defaultTenant, isLoading: true };

    case CREATE.LOADING:
        // toast.info('Tenant opslaan...', {
        //     toastId: 'create-pallet',
        //     autoClose: 1500,
        // });
        return { ...state, isUpdating: true };

    case UPDATE.LOADING:
        toast.info("Tenant opslaan...", {
            toastId: `update-tenant${action.payload.request.data.tenant_id}`,
            autoClose: 1500,
        });
        return { ...state, isUpdating: true };

    case DELETE.LOADING:
        toast.warning("Tenant verwijderen...", {
            toastId: "delete-tenant",
            autoClose: 1500,
        });
        return {
            ...state,
            isUpdating: true,
            items: {
                results: state.items.results.filter((x) => x.tenant_id !== action.payload.request.tenant_id),
            },
        };
    case LIST_MASTER_DEFECTS.LOADING:
        return { ...state, defects: undefined, isLoading: true };
    case LIST_FIELD_OPTIONS.LOADING:
        return { ...state, options: undefined, isLoading: true };


    case LIST_ALL_MASTER_DEFECTS.LOADING:
    case LIST_ALL_FIELD_OPTIONS.LOADING:
        return state;
    case CREATE_FIELD_OPTION.LOADING:
    case UPDATE_FIELD_OPTION.LOADING:
    case DELETE_FIELD_OPTION.LOADING:
    case CREATE_CONFIG.LOADING:
    case UPDATE_CONFIG.LOADING:
    case DELETE_CONFIG.LOADING:
        return { ...state, isLoading: true };

    case LIST.ERROR:
    case GET.ERROR:
    case LIST_FIELD_OPTIONS.ERROR:
    case LIST_ALL_FIELD_OPTIONS.ERROR:
    case CREATE_FIELD_OPTION.ERROR:
    case DELETE_FIELD_OPTION.ERROR:
    case UPDATE_FIELD_OPTION.ERROR:
    case CREATE_CONFIG.ERROR:
    case DELETE_CONFIG.ERROR:
    case UPDATE_CONFIG.ERROR:
    case CREATE.ERROR:
    case UPDATE.ERROR:
    case DELETE.ERROR:
    case GET_CONFIG.ERROR:

        return { ...state, isLoading: false, isUpdating: false };

    case LIST.SUCCESS:
        return { ...state, isLoading: false, items: action.payload.data };

    case GET.SUCCESS:
        return { ...state, isLoading: false, current: action.payload.data };

    case CREATE.SUCCESS:
        // toast.update('create-pallet', { render: "Pallet opgeslagen!", type: toast.TYPE.SUCCESS, autoClose: 3500});
        return {
            ...state, isLoading: false, isUpdating: false, current: action.payload.data,
        };

    case UPDATE.SUCCESS:
        toast.update(`update-tenant${action.payload.data.tenant_id}`, { render: "Tenant opgeslagen!", type: "success", autoClose: 3500 });
        return { ...state, isLoading: false, isUpdating: false, current: action.payload.data };


    case DELETE.SUCCESS:
        toast.update("delete-tenant", { render: "Tenant verwijderd", type: "warning", autoClose: 3500 });
        return {
            ...state, isLoading: false, isUpdating: false, current: action.payload.data,
        };

    case LIST_FIELD_OPTIONS.SUCCESS:
        return { ...state, options: action.payload.data, isLoading: false };

    case LIST_MASTER_DEFECTS.SUCCESS:
        return { ...state, defects: action.payload.data, isLoading: false };

    case LIST_ALL_FIELD_OPTIONS.SUCCESS: {
        const options_by_field = {};
        const options = action.payload.data;
        const { tenant_id } = action.meta.previousAction.payload.request;
        // Organize the options by field
        options.forEach((option) => {
            const { field } = option;
            if (!options_by_field[field]) {
                options_by_field[field] = [];
            }
            options_by_field[field].push(option);
        });
        return { ...state, options_by_field: { ...state.options_by_field, [tenant_id]: options_by_field }, isLoading: false };
    }

    case UPDATE_CONFIG.SUCCESS:
    case LIST_ALL_CONFIGS.SUCCESS: {
        const configs = action.payload.data;
        const { tenant_id } = action.meta.previousAction.payload.request;

        let { currentConfig } = state;

        if (currentConfig) {
            currentConfig = configs.find((config) => config.id === currentConfig.id) || currentConfig;
        }

        return { ...state,
            configs: { ...state.configs, [tenant_id]: configs },
            currentConfig,
            isLoading: false };
    }

    case LIST_ALL_MASTER_DEFECTS.SUCCESS: {
        const defects = action.payload.data;
        const { tenant_id } = action.meta.previousAction.payload.request;
        const defects_by_fruit_type = {};
        defects.forEach((defect) => {
            const { fruit_type } = defect;
            if (!defects_by_fruit_type[fruit_type]) {
                defects_by_fruit_type[fruit_type] = [];
            }
            defects_by_fruit_type[fruit_type].push(defect);
        });

        return { ...state, defects_by_fruit_type: { ...state.defects_by_fruit_type, [tenant_id]: defects_by_fruit_type }, isLoading: false };
    }

    case LIST_ALL_CONFIG_REVISIONS.SUCCESS: {
        return { ...state, configRevisions: action.payload.data, isLoading: false };
    }
    case GET_CONFIG.SUCCESS:
    case CREATE_CONFIG.SUCCESS: {
        return { ...state, currentConfig: action.payload.data, isLoading: false };
    }
    case UPDATE_CONFIG_STATE.SUCCESS: {
        return { ...state, currentConfig: action.payload.data, isLoading: false };
    }

    case CREATE_FIELD_OPTION.SUCCESS:
        return { ...state, options: action.payload.data, isLoading: false };

    case UPDATE_FIELD_OPTION.SUCCESS:
        return { ...state, options: action.payload.data, isLoading: false };

    case DELETE_FIELD_OPTION.SUCCESS:
        return { ...state, options: action.payload.data, isLoading: false };

    case CREATE_MASTER_DEFECT.SUCCESS:
        return { ...state, defects: action.payload.data, isLoading: false };

    case UPDATE_MASTER_DEFECT.SUCCESS:
        return { ...state, defects: action.payload.data, isLoading: false };

    case DELETE_MASTER_DEFECT.SUCCESS:
        // * method not alllowed for now. instead just update defect to be inactive
        return { ...state, defects: action.payload.data, isLoading: false };

    case DELETE_CONFIG.SUCCESS:
        return { ...state, configs: action.payload.data, isLoading: false };


    default:
        return state;
    }
};
