/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader
} from "reactstrap";

export const modalResult = {
    cancel: "cancel",
    save: "save",
    skip: "skip"
};

export default function AvosModal({
    header, children, isOpen, onClose, disableSaveButton, size, showSkipButton, showSaveButton, showCancelButton,
    skipButtonText,
    saveButtonText,
    cancelButtonText
}) {
    return (
        <Modal isOpen={isOpen} size={size} toggle={() => onClose(modalResult.cancel)}>
            <ModalHeader toggle={() => onClose(modalResult.cancel)} >{ header }</ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
            <ModalFooter>
                {showCancelButton && <Button color="light" onClick={() => onClose(modalResult.cancel)}>{cancelButtonText}</Button>}
                {showSkipButton && <Button color="light" onClick={() => onClose(modalResult.skip)}>{skipButtonText}</Button>}
                {showSaveButton && <Button disabled={disableSaveButton} color="success" onClick={() => onClose(modalResult.save)}>{saveButtonText}</Button>}
            </ModalFooter>
        </Modal >
    );
}

AvosModal.propTypes = {
    header: PropTypes.string,
    size: PropTypes.string,
    children: PropTypes.any,
    onClose: PropTypes.func,
    isOpen: PropTypes.bool,
    disableSaveButton: PropTypes.bool,
    showSkipButton: PropTypes.bool,
    skipButtonText: PropTypes.string,
    showSaveButton: PropTypes.bool,
    saveButtonText: PropTypes.string,
    showCancelButton: PropTypes.bool,
    cancelButtonText: PropTypes.string,
};

AvosModal.defaultProps = {
    size: "xl",
    showSaveButton: true,
    showCancelButton: true,
    skipButtonText: "Skip",
    saveButtonText: "Save",
    cancelButtonText: "Cancel"
};
