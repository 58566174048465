import { faCaretDown, faCaretUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { listLayers } from "../../actions/Layers/actions";
import { useFruitTypes } from "../../utils/useUserPreferences";
import Field from "../scenes/Layers/fields/Field"; // Assuming that Field exports a type for its props

export const SortDirection = {
    ASC: "ASC",
    DESC: "DESC",
    NONE: null
} as const;

interface SortableHeaderProps {
    field: Field; // Assuming FieldType is the correct type for `Field`
}

const LayerSortableHeader: React.FC<SortableHeaderProps> = ({ field }: SortableHeaderProps) => {
    const dispatch = useDispatch();
    const filter = useSelector((state: any) => state?.layers?.filter);

    // Depends on our backend implementation
    const isSortable = field.is_sortable();

    const order_field_name = field.get_backend_fieldname()[0];
    const [current_order_field_name, current_direction] = filter.ordering?.split(",") || [null, null];
    const currentFieldOrdered = current_order_field_name === order_field_name;
    const selectedFruitTypes = useFruitTypes();

    const updateOrdering = (direction: string) => {
        dispatch(
            listLayers({ ...filter, offset: 0, ordering: `${order_field_name},${direction}`, fruit_type: selectedFruitTypes }) as any
        );
    };

    const handleClick = () => {
        if (!isSortable) {
            return false;
        }
        // If another field was ordered, default to ASC
        if (!currentFieldOrdered) {
            updateOrdering(SortDirection.ASC);
        } else {
            const direction = current_direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;
            updateOrdering(direction);
        }

        return true;
    };

    return (
        <div className={`d-flex align-items-center ${(isSortable ? "cursor-pointer" : "")}`} onClick={handleClick}>
            <div>{field.label}</div>
            {isSortable && currentFieldOrdered && (
                <div>
                    {current_direction === SortDirection.ASC && (
                        <span className="p-2">
                            <FontAwesomeIcon icon={faCaretUp} />
                        </span>
                    )}
                    {current_direction === SortDirection.DESC && (
                        <span className="p-2">
                            <FontAwesomeIcon icon={faCaretDown} />
                        </span>
                    )}
                </div>
            )}
        </div>
    );
};

export default LayerSortableHeader;
