import Field, { DataSource } from "./Field";

class AggregatedFruitField extends Field {
    field_type = "aggregated_fruit_field";

    backend_table = "check";

    // * set the value from one of the the objects
    set_value(datasource: DataSource): this {
        this.value = this.value_filter(datasource.check ? datasource.check[this.fieldname_getter] : "-", datasource);
        return this;
    }

}

export default AggregatedFruitField;
