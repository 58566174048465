/** @jsxImportSource @emotion/react */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Col, FormGroup, FormText, Input, Label, Row } from "reactstrap";
import { updateTenantState } from "../../../actions/Tenants/actions";
import { RadioButtons } from "../../Forms/RadioButton";


interface ListTenantLegacyFeaturesProps {
    title: string;
}

function badge_not_sure() {
    return <Badge color="success">Testable: Yes?</Badge>;
}

function badge_no() {
    return <Badge color="danger">Testable: No</Badge>;
}

function badge() {
    return <Badge color="success">Testable: Yes</Badge>;
}

const features = [
    {
        name: "active",
        title: "Active",
        description: "Is this tenant able to login?",
        testable: badge_no,
    },
    {
        name: "uses_kg",
        title: "Uses kg",
        description: "Do these tenant members measure pressure in KG or LBS.",
        testable: badge,
    },
    {
        name: "has_trained_model",
        title: "Has Trained Model",
        description: "Does this tenant have a trained model in place? If not, skip the atron results in the add check flow",
        testable: badge,
    },
    {
        name: "has_imported_pallets",
        title: "Has Imported Pallets",
        description: "Does this tenant have autmaticly imported palets? If not, create pallets on the fly.",
        testable: badge,
    },
    {
        name: "has_avos_maxi",
        title: "Has AVOS Maxi",
        description: "Does this tenant has access to a Maxi dashboard.",
        testable: badge,
    },
    {
        name: "ask_for_pallet_meta",
        title: "Ask For Pallet Meta (Legacy)",
        description: "Do we ask for extra pallet meta data like growercodes and required ripening stage? Currently not implemented.",
        testable: badge,
    },
    {
        name: "show_avos_raw_pressure",
        title: "Show Avos Raw Pressure",
        description: "Show raw sensor pressure data from avos",
        testable: badge,
    },
    {
        name: "show_avos_ripening_stage",
        title: "Show Avos Ripening Stage",
        description: "Show ripening stage from avos",
        testable: badge,
    },
    {
        name: "show_avos_kg_pressure",
        title: "Show Avos kg Pressure",
        description: "Show kg from avos regression model",
        testable: badge,
    },
    {
        name: "has_two_fta",
        title: "Has multiple FTA",
        description: "Uses pressure_1 and pressure_2",
        testable: badge_not_sure,
    },
    {
        name: "show_powerbi_link",
        title: "Show PowerBI link",
        description: "Show PowerBI link on the header for this tenant",
        testable: badge,
    },
];

function ListTenantLegacyFeatures({ title }: ListTenantLegacyFeaturesProps) {
    const current = useSelector<any, any>((state) => state.tenants.current);
    const {
        pallet_datamart,
        quality_check_size,
    } = current;
    const dispatch = useDispatch();

    const onChange = (e) => {
        dispatch(updateTenantState({ ...current, [e.target.name]: e.target.value }));
    };

    const stringify = (value) => (value === null ? "" : value);

    const true_or_false = [{ value: true, text: "True" }, { value: false, text: "False" }];

    return (
        <div id={title}>
            <Row>
                <Col md="12">
                    <FormGroup className="pb-2">
                        <Label for="pallet_datamart">Pallet Datamart</Label>
                        <Input type="text" name="pallet_datamart" id="pallet_datamart" value={stringify(pallet_datamart)} onChange={onChange} />
                        <FormText color="muted">Fill the key of the database entry from which we can query pallet data. Only available for Greencell right now. <Badge color="success">Testable: Yes</Badge></FormText>
                    </FormGroup>
                </Col>
                <Col md="12">
                    <FormGroup className="pb-2">
                        <Label for="quality_check_size">Quality Check Size</Label>
                        <Input type="number" name="quality_check_size" id="quality_check_size" value={quality_check_size} onChange={onChange} />
                        <FormText color="muted">How many avocados are there in one check? This is relevant for the bulk edit and pressure calibration. <Badge color="success">Testable: Yes</Badge></FormText>
                    </FormGroup>
                </Col>

                {features.map((i) => <FormGroup className="pb-2" key={i.name}>
                    <Label for={i.name}>{i.title}</Label>
                    <div>
                        <RadioButtons name={i.name} current={current[i.name]} color="light" options={true_or_false} onChange={(_, value) => dispatch(updateTenantState({ ...current, [i.name]: (typeof value) !== "boolean" ? current[i.name] : value }))}></RadioButtons>
                    </div>
                    <FormText color="muted">{i.description} {i.testable()}</FormText>
                </FormGroup>)}
            </Row>
        </div>
    );
}

export default ListTenantLegacyFeatures;
