import { useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button, FormGroup, FormText, Input, Label
} from "reactstrap";
import { createTenant, listTenants } from "../../../actions/Tenants/actions";

const initialState = {
    tenant_name: "",
    tenant_id: "",
};

function reducer(state, { field, value }) {
    return {
        ...state,
        [field]: value,
    };
}

export default function TenantCreateForm() {
    const user = useSelector((state) => state.auth.user);
    const tenants = useSelector((state) => state.tenants);
    const dispatch = useDispatch();
    const [state, dispatchLocal] = useReducer(reducer, initialState);

    const onChange = (e) => {
        dispatchLocal({ field: e.target.name, value: e.target.value });
    };
    const submit = () => {
        Promise.resolve(
            dispatch(createTenant(state)),
        ).then(() => {
            dispatch(listTenants());
        });
    };
    const { tenant_name, tenant_id } = state;

    if (user.tenant.tenant_id === "a6aff297-ac1b-4e81-88a6-420591d31f31") {
        return (
            <div>
                <h2 className="">Add a new tenant</h2>
                <FormGroup>
                    <Label for="tenant_name">Tenant name</Label>
                    <Input type="text" name="tenant_name" id="tenant_name" value={tenant_name} onChange={onChange} />
                    <FormText color="muted">Give this tenant a recognizable name</FormText>
                </FormGroup>
                <FormGroup>
                    <Label for="tenant_id">Tenant ID</Label>
                    <Input type="text" name="tenant_id" id="tenant_id" value={tenant_id} onChange={onChange} />
                    <FormText color="muted">Unique identifier for this tenant, go to <a href="https://www.whatismytenantid.com" rel="noopener noreferrer" target="_blank">https://www.whatismytenantid.com</a></FormText>
                </FormGroup>
                <FormGroup>
                    <Button disabled={tenants.isUpdating} onClick={submit}>Submit</Button>
                </FormGroup>
            </div>);
    }
    return (null);
}
