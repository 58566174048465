import { LayerFilter } from "../../../../actions/Layers/constants";
import { applyDisplayFilterLayerFields } from "../../../../actions/Tenants/config/applyDisplayFilter";
import useConfig from "../../../../actions/Tenants/config/configHook";
import Field from "./Field";

export interface UseSwitchTabProps {
    onFilterBundleChange: (newFilter: LayerFilter) => void;
}

export default function useSwitchTab({ onFilterBundleChange }: UseSwitchTabProps) {
    const config = useConfig();
    const switchTab = (tab) => {

        let newFilter: LayerFilter = {
            location: tab.location,
            layer_type: tab.layer_type,
            view_strategy: tab.view_strategy,
            fruit_type: [],
            offset: 0,
            ordering: tab.default_ordering || "check.created,DESC"
        };

        const stage = config.get_stage(tab.location);
        const fields = applyDisplayFilterLayerFields(stage?.layer_overview_fields || [], { fruit_type: newFilter.fruit_type, layer_type: newFilter.layer_type });

        // add select_fields to query
        newFilter = {
            ...newFilter,
            select_fields: Array.isArray(fields) ? fields.flatMap((i: Field) => i.get_backend_fieldname()) : []
        };
        onFilterBundleChange(newFilter);
    };

    return switchTab;
}
