import { toast } from "../../utils/toast";
import { CREATE as CREATE_AVOCADO, PATCH as PATCH_AVOCADO, UPDATE as UPDATE_AVOCADO } from "../Avocados/actionsTypes";
import {
    CREATE, DELETE, GET, LIST,
    PATCH,
    UPDATE
} from "./actionsTypes";

import { formatFruit2Frontend } from "../Avocados/utils";
import { defaultCheck } from "./constants";
import { populate_dummy_images } from "./dummy_images";
import { formatCheck2Frontend } from "./util";

const checks = {
    items: false,
    isLoading: false,
    isUpdating: false,
    current: defaultCheck,
    filter: { search: "", limit: 50, ordering: "" },
};
export default (state = checks, action) => {
    let current;
    switch (action.type) {
    case LIST.FILTER:
        return { ...state, filter: action.payload };
    case LIST.LOADING:
        return { ...state, filter: action.payload.request.params, isLoading: true };
    case GET.LOADING:
        return { ...state, isLoading: true };

    case CREATE.LOADING:
        // toast.info('Saving check...', {
        //     toastId: 'create-check',
        //     autoClose: 1000,
        // });
        return { ...state, isUpdating: true };

    case UPDATE.LOADING:
        return { ...state, isUpdating: true };

    case PATCH.STATE:
        return { ...state,
            current: {
                ...state.current,
                [action.payload.field]: action.payload.value,
            } };

    case PATCH.LOADING:
        return { ...state,
            current: {
                ...state.current,
                [action.payload.request.data.field]: action.payload.request.data.state_value,
            },
            isUpdating: true };
    case PATCH.SUCCESS:
        return { ...state, isUpdating: false };
    case PATCH.ERROR:
        return { ...state, isUpdating: false };

    case DELETE.LOADING:
        toast.warning("Deleting check...", {
            toastId: "delete-checks",
            autoClose: 1500,
        });
        return {
            ...state,
            isUpdating: true,
            items: {
                results: state.items.results.filter((x) => x.check_id !== action.payload.request.check_id),
            },
        };

    case LIST.ERROR:
    case GET.ERROR:
    case CREATE.ERROR:
    case UPDATE.ERROR:
    case DELETE.ERROR:
        return { ...state, isLoading: false, isUpdating: false };

    case LIST.SUCCESS:
        return { ...state, isLoading: false, items: action.payload.data.map(formatCheck2Frontend) };
    case LIST.RESET:
        return { ...state, items: false, filter: { search: "", limit: 50, ordering: "" } };

    case GET.SUCCESS:
        return { ...state, isLoading: false, current: filter_check(action.payload.data) };

    case CREATE.SUCCESS:
        // toast.update('create-check', { render: "Check started", type: toast.TYPE.SUCCESS, autoClose:1000 });
        return {
            ...state, isLoading: false, isUpdating: false, current: formatCheck2Frontend({ ...action.payload.data, avocados: [] }),
        };

    case UPDATE_AVOCADO.SUCCESS:
        current = filter_check({ ...state.current, avocados: state.current.avocados.map((i) => (action.payload.data.avocado_id === i.avocado_id ? action.payload.data : i)) });
        return { ...state, isLoading: false, current };

    case PATCH_AVOCADO.STATE:
        current = filter_check({ ...state.current, avocados: state.current.avocados.map((i) => (action.payload.avocado_id === i.avocado_id ? { ...i, [action.payload.field]: action.payload.value } : i)) });
        return { ...state, isLoading: false, current };

    case PATCH_AVOCADO.LOADING:
        current = filter_check({ ...state.current, avocados: state.current.avocados.map((i) => (action.payload.request.data.avocado_id === i.avocado_id ? { ...i, [action.payload.request.data.field]: action.payload.request.data.state_value } : i)) });
        return { ...state, isLoading: false, current };

    case CREATE_AVOCADO.SUCCESS:
        current = filter_check({ ...state.current, avocados: [...state.current.avocados, action.payload.data] });
        return { ...state, isLoading: false, current };

    case UPDATE.SUCCESS:
        toast.update(`update-check-${action.payload.data.check_id}`, { render: "Check updated!", type: "success", autoClose: 3500 });
        return { ...state, isUpdating: false, current: filter_check(action.payload.data) };

    case DELETE.SUCCESS:
        toast.update("delete-checks", { render: "Check deleted!", type: "warning", autoClose: 3500 });
        return {
            ...state, isLoading: false, isUpdating: false, current: action.payload.data,
        };

    default:
        return state;
    }
};

const filter_check = (check) => formatCheck2Frontend({
    ...check,
    avocados: check.avocados.filter((i) => i.is_invalid === false).map((i) => populate_dummy_images(formatFruit2Frontend(i)))
});
