/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import Footer from "./Footer";
import Header from "./Header";

export default function DefaultLayout({ children }) {
    return <div className="d-flex flex-column bg-white" css={css`min-height: 100vh;`}>
        <Header />
        {children}
        <div className="mt-auto">
            <Footer />
        </div>
    </div>;
}

DefaultLayout.propTypes = {
    children: PropTypes.any,
};
