/** @jsxImportSource @emotion/react */
import { faTemperatureHigh } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useConfig from "../../../../actions/Tenants/config/configHook";
import { Loading } from "../../../Helper/Loading";
import { MetaRow } from "./LayerMeta";
import { LAYER_TYPE } from "../../../../actions/Layers/constants";

export default function LayerParentsChildren() {
    const isLoading = useSelector((state) => state.layers.isLoading);
    const { children, parents } = useSelector((state) => state.layers.current);
    const layer = useSelector((state) => state.layers.current);

    const childrenWithMeta = useSelector((state) => state.layers.children);

    const layer_with_temp_sensors = childrenWithMeta?.results?.filter((i) => i.temptale_number && i?.temptale_number !== "None" && i?.temptale_number !== "-")
        .map((i) => i.label) || [];

    const config = useConfig();
    const displayLayerType = (type) => config.get_raw_layer_config(type)?.text || type;

    const layer_config = config.get_layer_config(layer);
    const { hide_sub_pallets } = layer_config;

    if (isLoading) {
        return <Loading />;
    }

    return <div className="pt-4">
        {parents && parents.length > 0 && <div>
            {parents.map((i, index) => <MetaRow key={index} label={displayLayerType(i.type)} value={<Link to={`/layer/${i.id}`}>{i.label}</Link>}></MetaRow>)}
        </div>}

        {children && children.length > 0 && <div className="pt-4">
            {children.filter((item) => !hide_sub_pallets || item.type !== LAYER_TYPE.SUBPALLET).map((i, index) => <MetaRow key={index} label={
                i.id && <div>
                    {displayLayerType(i.type)} {" "}
                    {layer_with_temp_sensors.includes(i.label) && (
                        <span className="me-2">
                            <FontAwesomeIcon icon={faTemperatureHigh} />
                        </span>
                    )}
                </div>
            } value={<Link to={`/layer/${i.id}`}>{i.label}</Link>}></MetaRow>)}
        </div>}

    </div>;
}
